<template>
		<ul class="middle-menu">
				<template v-for="(menuItem, itemIndex) in menu" :key="itemIndex">
						<li class="menu-item" :class="{featured: menuItem.featured}">
								<router-link
										v-if="menuItem.link === '/'"
										:to="`/`"
										@click="closeMobileMenu"
										v-slot="{ isExactActive, isActive }"
								>
										<fp-icon
												v-if="menuItem.animated_icon"
												:animated="menuItem.animated_icon"
												target=".menu-item"
												size="32"
										></fp-icon>
										<menu-icon
												v-else-if="menuItem.icon"
												src="menuItem.icon"
												src-hover="menuItem.icon_active"
												alt="menuItem.label"
												:class="{ active: isExactActive || isActive }"
										></menu-icon>
										<transition>
												<span v-if="showTitles" class="m-label">{{ menuItem.label }}</span>
										</transition>
								</router-link>
								<router-link
										v-else-if="menuItem.link"
										:to="`/projects/${this.currentProjectId}/${menuItem.link}`"
										@click="closeMobileMenu"
								>
										<fp-icon
												v-if="menuItem.animated_icon"
												:animated="menuItem.animated_icon"
												target=".menu-item"
												size="32"
										></fp-icon>
										<menu-icon
												v-else-if="menuItem.icon"
												:src="menuItem.icon"
												:src-hover="menuItem.icon_active"
												:alt="menuItem.label"
										></menu-icon>
										<transition>
												<span v-if="showTitles" class="m-label">{{ menuItem.label }}</span>
										</transition>
								</router-link>
								<router-link
										v-else
										:to="`/projects/${this.currentProjectId}`"
										@click="closeMobileMenu">
										<fp-icon
												v-if="menuItem.animated_icon"
												:animated="menuItem.animated_icon"
												target=".menu-item"
												size="32"
										></fp-icon>
										<menu-icon
												v-else-if="menuItem.icon"
												:src="menuItem.icon"
												:src-hover="menuItem.icon_active"
												:alt="menuItem.label"
										></menu-icon>
										<transition>
												<span v-if="showTitles" class="m-label">{{ menuItem.label }}</span>
										</transition>
								</router-link>
						</li>
						<li v-if="isMobile" class="divider"></li>
				</template>
		</ul>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import FpIcon from "@/components/FpIcon.vue"
import MenuIcon from "@/components/MenuIcon.vue"

export default {
		name: "MenuBarMiddle",
		components: {FpIcon, MenuIcon},
		props: {
				showTitles: {
						type: Boolean,
						default: true
				}
		},
		computed: {
				...mapGetters(['menu','currentProjectId', 'isMobile'])
		},
		methods: {
				...mapActions(['closeMobileMenu'])
		}
}
</script>

<style scoped>

</style>
