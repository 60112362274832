<template>
    <label class="fp-checkbox" :class="{inline}">
        <input type="checkbox" v-bind="$attrs" v-model="checked">
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.420898" y="0.25" width="19.5" height="19.5" rx="4.75" fill="white" stroke="white"
                  stroke-width="0.5"/>
            <path
                d="M14.8083 3C12.2923 5.7231 10.5028 8.31281 8.6789 11.7072C7.55793 10.2475 6.65601 9.29467 5.725 8.45H3C5.1274 10.9388 6.2719 12.8472 8.45 16.625H10.2667C12.0107 11.8698 13.6003 8.52076 17.5333 3H14.8083Z"
                fill="black"/>
        </svg>
        <slot></slot>
    </label>
</template>

<script>
export default {
    name: "FpCheckbox",
    props: {
        modelValue: Boolean,
				inline: {
						default: false,
						type: Boolean
				}
    },
    data() {
        return {
            checked: false
        }
    },
    watch: {
        checked(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.$emit('update:modelValue', newVal)
            }
        },
        modelValue(newVal) {
          this.checked = newVal
        }
    },
    mounted() {
        if(typeof this.modelValue !== 'undefined'){
            this.checked = this.modelValue
        }
    }
}
</script>

<style scoped lang="scss">
.fp-checkbox {
    cursor: pointer;
    display: inline-flex;
    flex-flow: row nowrap;
    gap: 10px;
    align-items: center;
    user-select: none;

		&.inline {
				display: inline-flex;
		}

    :deep(input[type="checkbox"]) {
        display: none;

        + svg path {
            display: none;
        }

        &:checked + svg path {
            display: block;
        }
    }
}
</style>
