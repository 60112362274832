<template>
		<div class="weather-widget-wrapper" v-if="enabled">
				<div class="weather-widget" v-if="weatherCity">
						<div class="city-time">{{weatherCity?.time}}</div>
						<div class="city-name">{{weatherCity?.name_he}}</div>
						<div class="city-temp">
								<img src="../assets/weather/png/039-sun.png" alt="" width="25px" height="25px">
								<span>{{weatherCity?.weather?.Temperature}}&deg;</span>
						</div>
				</div>
		</div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
		name: "WeatherWidget",
		computed: {
				enabled(){
						return this.weatherWidget && this.weatherWidget.show
				},
				...mapGetters(['settings','weatherWidget', 'weatherCity'])
		},
		data(){
				return {
						loading: false,
						interval: null
				}
		},
		async mounted() {
				this.loading = true
				await this.$store.dispatch('loadWeatherCity')
				this.loading = false
				this.interval = setInterval(() => {
						this.$store.dispatch('loadWeatherCity')
				}, 30000)
		},
		beforeUnmount() {
				if(this.interval){
						clearInterval(this.interval)
				}
		}
}
</script>

<style scoped lang="scss">
.weather-widget-wrapper {
		display: flex;
		justify-content: flex-end;

		.weather-widget {
				width: 180px;
				background-color: rgb(0 0 0 / 55%);
				backdrop-filter: blur(5px);
				border-radius: 4px;
				margin: 10px;
				padding: 8px 10px;
				display: flex;
				font-size: 1.2rem;
				gap: 5px;
				align-items: center;

				.city-name {
						flex-grow: 1;
						text-align: center;
				}

				.city-temp {
						display: flex;
						flex-flow: row nowrap;
						align-items: center;
						img {
								width: 25px;
								margin: 0 5px;
						}
				}
		}
}
</style>
