<template>
	<div class="page favorite-apartments">
			<div class="favs-modal">
					<div class="favs-header">
						<div class="favs-title">{{$t('מועדפים')}}</div>
						<close-btn @click="$router.back()"></close-btn>
					</div>
					<div class="favs-table">
							<table>
									<thead>
									<tr>
											<th></th>
											<th>{{$t('טיפוס')}}</th>
											<th>{{$t('בניין')}}</th>
											<th>{{$t('קומה')}}</th>
											<th>{{$t('מספר חדרים')}}</th>
											<th v-if="apartmentsFilter?.apartment_sm?.label">
													<span v-if="apartmentsFilter.apartment_sm.unit_of_measure">{{apartmentsFilter.apartment_sm.unit_of_measure}}&nbsp;</span>
													<span>{{apartmentsFilter.apartment_sm.label}}</span>
											</th>
											<th v-else>{{$t('מ"ר דירה')}}</th>
											<th v-if="apartmentsFilter?.porch_sm?.label">
													<span v-if="apartmentsFilter.porch_sm.unit_of_measure">{{apartmentsFilter.porch_sm.unit_of_measure}}&nbsp;</span>
													<span>{{apartmentsFilter.porch_sm.label}}</span>
											</th>
											<th v-else>{{$t('מ"ר מרפסת')}}</th>
											<th>{{$t('כיוון')}}</th>
											<th>{{$t('השוואה')}}</th>
											<th></th>
											<th></th>
									</tr>
									</thead>
									<tbody>
									<template v-for="(aptId, aptIndex) in apartmentFavs" :key="aptIndex">
											<tr v-if="products[aptId]">
													<td><apartment-fav-button :apt-id="aptId"></apartment-fav-button></td>
													<td>{{products[aptId].type}}</td>
													<td>{{products[aptId].type_building?.name}}</td>
													<td>{{products[aptId].type_floors}}</td>
													<td>{{products[aptId].type_rooms}}</td>
													<td>{{products[aptId].type_dimensions?.apartment_sm}}</td>
													<td>{{products[aptId].type_rooms?.porch_sm}}</td>
													<td>{{products[aptId].type_direction}}</td>
													<td><fp-checkbox v-if="compareEnabled" v-model="localAptComp[aptId]"></fp-checkbox></td>
													<td><apartment-availability :available="products[aptId].available"></apartment-availability></td>
													<td></td>
											</tr>
									</template>
									</tbody>
							</table>
					</div>
			</div>
			<apartments-compere></apartments-compere>
			<apartments-list
					:apartments="products"
					scroll="horizontal"
					@apartment-clicked="navigateToApartment"
					:show-compare="false"
			></apartments-list>
	</div>
</template>

<script>
import ApartmentsCompere from "@/components/ApartmentsCompere";
import {mapGetters} from "vuex";
import ApartmentFavButton from "@/components/buttons/ApartmentFavButton";
import FpCheckbox from "@/components/buttons/FpCheckbox";
import ApartmentsList from "@/components/ApartmentsList";
import CloseBtn from "@/components/buttons/CloseBtn";
import ApartmentAvailability from "@/components/apartment/ApartmentAvailability";
export default {
		name: "FavoriteApartments",
		components: {ApartmentAvailability, CloseBtn, ApartmentsList, FpCheckbox, ApartmentFavButton, ApartmentsCompere},
		computed: {
				...mapGetters(['apartmentsCompare','products', 'compareEnabled','apartmentFavs','apartmentPageEnabled', 'currentProjectId', 'apartmentsFilter'])
		},
		watch: {
				localAptComp: {
						handler(newVal){
								this.$store.commit('apartmentsCompare', newVal)
						},
						deep: true
				},
				apartmentsCompare(newVal){
						this.localAptComp = newVal
				}
		},
		data(){
				return {
						localAptComp: {}
				}
		},
		methods: {
				navigateToApartment(apt){
						if(this.apartmentPageEnabled && apt.type_show_page){
								this.$router.push({name: 'apartment', params: {projectId: this.currentProjectId, apartmentId: apt.id}})
						}
				}
		},
		mounted() {
				this.localAptComp = this.apartmentsCompare
		},
		beforeRouteEnter(to, from, next){
				next(async (vm) => {
						await vm.$store.dispatch('searchProducts')
				})
		},
}
</script>

<style scoped lang="scss">
.favorite-apartments {
		display: flex;
		flex-flow: column;

		:deep(.apartments-compere){
				position: relative;
				left: auto;
				bottom: auto;
		}

		.favs-modal {
				margin: auto auto 40px;
				background-color: rgb(0 0 0 / 80%);
				width: 100%;
				max-width: 720px;
				padding: 15px;

				.favs-header {
						display: flex;
						margin-bottom: 40px;
				}

				.favs-title {flex-grow: 1;}
		}

		.favs-table {
				max-width: 100%;
				overflow-x: auto;

				table {
						width: 100%;
						text-align: center;

						thead {
								border-bottom: solid 1px;
						}

						thead tr th {
								padding: 0 5px 10px;
						}

						thead tr:first-child th:first-child {
								text-align: start;
						}

						tbody tr td {
								padding: 5px 5px;
						}

						tbody tr:first-child td {
								padding-top: 10px;
						}
				}
		}

		:deep(.fav-button-wrapper) a {
				display: none;
		}
}

.fp-side-menu-wrapper + .fp-app-wrapper {
		.favs-modal {
				background-color: transparent;
				background-image: linear-gradient(to left, rgb(0 0 0 / 80%) 50%,rgb(0 0 0 / 90%) 100%);
				backdrop-filter: blur(5px);
		}

		.favorite-apartments {
				padding-bottom: 50px;
		}
}
</style>
