<template>
    <div class="slider-gallery" :class="{'auto-height': autoHeight}">
        <Swiper @afterInit="sliderInit" @slideChange="slideChange" :navigation="false" :autoHeight="autoHeight" :centeredSlides="isMobile" :slidesPerView="isMobile ? 1.2 : 'auto'" :spaceBetween="isMobile ? 12 : 0">
            <SwiperSlide v-for="(slide, index) in slides" :key="index" :style="{height: slideHeight}">
                <slot :slide="slide" :index="index"></slot>
								<illustration-only-disclaimer v-if="showDisclaimer"></illustration-only-disclaimer>
            </SwiperSlide>
        </Swiper>
        <button v-if="!isMobile && slidesCount > 0 && !nextDisabled && showArrows" @click="nextSlide()" :disabled="nextDisabled" class="swiper-arrow swiper-next" type="button">
            <fp-icon symbol="arrow-left"></fp-icon>
        </button>
        <button v-if="!isMobile && slidesCount > 0 && !prevDisabled && showArrows" @click="prevSlide()" :disabled="prevDisabled" class="swiper-arrow swiper-prev" type="button">
            <fp-icon symbol="arrow-right"></fp-icon>
        </button>
        <ul v-if="!isMobile && showDots" class="dots-nav">
            <li v-for="index in this.slidesCount" :key="index">
                <button @click="slideTo(index - 1)" type="button" class="dot" :class="{current: (currentSlideIndex + 1) === index}">{{index}}</button>
            </li>
        </ul>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, {Autoplay, Navigation, Pagination} from 'swiper';
import 'swiper/css';
import FpIcon from "@/components/FpIcon";
import {mapGetters} from "vuex";
import IllustrationOnlyDisclaimer from "@/components/IllustrationOnlyDisclaimer";
SwiperCore.use([Autoplay, Navigation, Pagination]);

export default {
    name: "SliderGallery",
    props: {
        slides: {
            required: true
        },
				autoHeight: {
						required: false,
						default: false,
						type: Boolean
				},
				height: {
						required: false,
						default: null,
						type: String
				},
				showArrows: {
						required: false,
						default: true,
						type: Boolean
				},
				showDots: {
						required: false,
						default: true,
						type: Boolean
				},
				showDisclaimer: {
						required: false,
						default: true,
						type: Boolean
				}
    },
    components: {
				IllustrationOnlyDisclaimer,
        FpIcon,
        Swiper, SwiperSlide
    },
    computed: {
        ...mapGetters(['isMobile', 'isSideMenu'])
    },
    watch:{
        slides(){
            setTimeout(() => {
                this.updateStatus()
            })
        }
    },
    data(){
        return {
            swiper: null,
            nextDisabled: false,
            prevDisabled: false,
            slidesCount: null,
            currentSlideIndex: 0,
            slideHeight: '85vh'
        }
    },
    methods:{
        sliderInit(swiper){
            this.swiper = swiper
            this.updateStatus()
            this.swiper.on('afterInit', this.updateStatus)
            this.swiper.on('slideChange', this.updateStatus)

            /*setTimeout(() => {
                this.swiper.updateAutoHeight(100)
            })
            setTimeout(() => {
                this.swiper.updateAutoHeight(100)
            }, 500)
            setTimeout(() => {
                this.swiper.updateAutoHeight(100)
            }, 1500)*/
        },
        nextSlide(){
            if(this.swiper){
                this.swiper.slideNext()
            }
        },
        prevSlide(){
            if(this.swiper){
                this.swiper.slidePrev()
            }
        },
        slideTo(index){
            if(this.swiper){
                this.swiper.slideTo(index)
            }
        },
        updateStatus(){
            this.nextDisabled = false
            this.prevDisabled = false
            this.prevDisabled = this.swiper.isBeginning
            this.nextDisabled = this.swiper.isEnd
            this.currentSlideIndex = this.swiper.activeIndex
            this.slidesCount = this.slides.length
            this.slideChange()
        },
        slideChange(e){
            const slide = this.swiper.slides

						if(e && typeof(e.activeIndex) === 'number'){
								if(this.currentSlideIndex !== e.activeIndex){
										this.currentSlideIndex = e.activeIndex
								}
						}

            setTimeout(() => {
                slide.each((e) => {
                    if(e.classList.contains('swiper-slide-active')){
                        const video = e.querySelector('video')
                        if(video){
                            video.muted = true
                            video.play()
                        }
                    }else{
                        const video = e.querySelector('video')
                        if(video){
                            video.pause()
                        }
                    }
                })
            }, 500)
        },
        setSlideHeight(height){
            this.slideHeight = height
        }
    },
    mounted() {
        if(this.isMobile){
						this.setSlideHeight('auto')
        }

				if(this.autoHeight){
						this.setSlideHeight('auto')
				}else if(this.height){
						this.setSlideHeight(this.height)
				}else if(this.isSideMenu){
						this.setSlideHeight('100vh')
				}
    }
}
</script>

<style scoped lang="scss">
.slider-gallery {
    position: relative;

    .swiper-arrow {
        position: absolute;
        top: 50%;
        z-index: 10;
        transform: translateY(-50%);
        background-color: #000;
        height: 40px;
        width: 40px;
        border: solid 1px #fff;
        border-radius: 8px;
        box-shadow: -1px 1px 3px 1px #000000f2;

        &[disabled] {
            opacity: .6;
        }
    }

    .swiper-arrow.swiper-next {
        left: 20px;
    }

    .swiper-arrow.swiper-prev {
        right: 20px;
    }

    .dots-nav {
        list-style: none;
        display: flex;
        gap: 10px;
        position: absolute;
        bottom: 10px;
        z-index: 10;
        left: 50%;
        transform: translateX(-50%);

        .dot {
            width: 15px;
            height: 15px;
            background-color: #000;
            border: solid 1px #fff;
            border-radius: 15px;
            opacity: .6;

            &.current {
                opacity: 1;
            }
        }
    }

    :deep(.swiper-slide) {
        height: 80vh;
        display: flex;
        align-items: stretch;
        justify-content: center;

        > div {
            display: flex;
            align-items: center;
        }

        video {
            max-width: 100%;
        }

				a {
						display: block;
						height: 100%;
				}

        img {
            max-height: 100%;
        }

        .img-cover {
            height: 100%;
            width: 100%;

            img,a {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}

.is-mobile {
    .slider-gallery {
        :deep(.swiper-slide) {
            display: flex;
            height: auto;
            align-items: center;

            > div {
                display: block;
            }

            video {
                width: 100%;
            }
        }
    }
}

.fp-side-menu-wrapper + .fp-app-wrapper {
		.slider-gallery:not(.auto-height) {
				:deep(.swiper-slide) {
						height: 100vh;
				}
		}
}

</style>
