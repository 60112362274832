<template>
		<nav class="side-menu" ref="sideMenu" :class="{'show-titles': showTitles}" @mouseenter.stop="hoverShowTitles" @mouseleave.stop="hoverHideTitles">
				<div class="top-area">
						<main-logo></main-logo>
						<transition>
							<language-switcher></language-switcher>
						</transition>
				</div>
				<menu-bar-middle :show-titles="showTitles"></menu-bar-middle>
				<button v-if="isTouchScreen" type="button" @click="toggleTitles">
						<fp-icon v-if="showTitles" symbol="arrow-right"></fp-icon>
						<fp-icon v-else symbol="arrow-left"></fp-icon>
				</button>
				<div class="bottom-area">
						<transition>
								<fp-icon-button
										v-if="showTitles"
										class="contact-btn"
										:class="{'has-title': showTitles}"
										animated="call-phone"
										state="hover-phone-ring"
										@click="toggleContactModal"
										symbol-size="32"
								>
											<span>{{$t('יצירת קשר')}}</span>
								</fp-icon-button>
								<fp-icon-button
										v-else
										class="contact-btn"
										:class="{'has-title': showTitles}"
										animated="call-phone"
										state="hover-phone-ring"
										@click="toggleContactModal"
										symbol-size="32"
								></fp-icon-button>
						</transition>
						<menu-bar-left></menu-bar-left>
				</div>
				<div class="credits">powered by <a href="https://studioy.io" target="_blank">studioY.io</a></div>
		</nav>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import FpIcon from "@/components/FpIcon";
import MenuBarLeft from "@/components/MenuBarLeft";
import MainLogo from "@/components/MainLogo";
import FpIconButton from "@/components/buttons/FpIconButton";
import MenuBarMiddle from "@/components/MenuBarMiddle.vue";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
		name: "SideMenu",
		components: {
				LanguageSwitcher,
				MenuBarMiddle,
				FpIconButton,
				MainLogo,
				MenuBarLeft,
				FpIcon
		},
		computed: {
				showTitles: {
						get(){
								return this.sideMenuShowTitles
						},
						set(value){
								this.setSideMenuTitles(value)
						}
				},
				...mapGetters([
						'settings',
						'isMobile',
						'mobileMenuOpen',
						'projectsPaneOpen',
						'currentProjectId',
						'isTouchScreen',
						'locale',
						'sideMenuShowTitles'
				]),
				...mapGetters({
						projectId: 'currentProjectId'
				})
		},
		watch:{
				locale(newVal, oldVal){
						if(newVal !== oldVal){
								this.hoverHideTitles()
						}
				}
		},
		methods:{
				closeMobileMenu(){
						if(this.isMobile && this.mobileMenuOpen){
								this.$store.commit('mobileMenuOpen', false)
						}
						if(this.projectsPaneOpen){
								this.$store.commit('projectsPaneOpen', false)
						}
				},
				toggleTitles(){
						this.showTitles = !this.showTitles;
				},
				hoverHideTitles(){
						if(!this.isTouchScreen){
								this.showTitles = false;
						}
				},
				hoverShowTitles(){
						if(!this.isTouchScreen){
								this.showTitles = true;
						}
				},
				toggleContactModal(){
						this.$store.commit('toggleContactModal')
				},
				...mapMutations({
						setSideMenuTitles: 'sideMenuShowTitles'
				})
		}
}
</script>

<style scoped lang="scss">
nav.side-menu {
		background-color: rgb(0, 0, 0,.8);
		backdrop-filter: blur(5px);
		height: calc(100% - 20px);
		padding: 10px 15px;
		width: 42px;
		font-size: 1.125rem;
		box-sizing: content-box;
		transition-property: width;
		transition-duration: .4s;
		transition-timing-function: ease;
		justify-content: center;
		display: flex;
		flex-flow: column;
		align-items: flex-start;
		overflow: hidden;
		position: relative;

		&.show-titles {
				width: 170px;
				background-color: transparent;
				background-image: linear-gradient(to left, rgb(0 0 0 / 88%) 20%,rgb(0 0 0 / 68%) 50%);
		}

		.top-area {
				position: absolute;
				top: 10px;
				right: 15px;
				left: 15px;
				text-align: center;
				display: flex;
				flex-flow: column;
				gap: 10px;
		}

		.bottom-area {
				position: absolute;
				bottom: 25px;
				right: 15px;
				left: 15px;
				display: flex;
				flex-flow: column;
				align-items: center;
		}

		.credits {
				opacity: 0;
				transition: opacity .4s ease, transform .4s ease;
				white-space: nowrap;
				left: 15px;
				right: 15px;
				transform: translateX(100%);
				background-color: transparent;
				font-size: .8rem;
				text-align: center;
		}

		.bottom-area {
				ul {
						display: flex;
						flex-flow: column;
						transition: transform .4s ease;
						transform: rotate(0);
						transform-origin: bottom left;
						:deep(button) {
								transition: transform .4s ease;
						}
				}

				:deep(.contact-btn.fp-icon-button) {

						&.has-title {
								border: solid 1px #fff;
								border-radius: 4px;
						}

						&.v-enter-active,
						&.v-leave-active {
								transition-duration: .3s;
								transition-property: transform;
								transition-timing-function: ease;
						}

						&.v-enter-from {
								transform: translateX(320px);
						}
						&.v-leave-to {
								transform: translateX(-320px);
						}

				}
		}

		&.show-titles {
				.bottom-area {
						ul {
								transform: rotate(-90deg) translateY(75%);
								:deep(button) {
										transform: rotate(90deg);
								}
						}
				}

				.credits {
						opacity: 1;
						transform: translateX(0);
				}
		}

		:deep(ul.middle-menu) {
				/*border-bottom: solid 1px #fff;*/
				li {
						/*border-top: solid 1px #fff;*/
						a {
								display: block;
								padding: 10px;
								white-space: nowrap;

								.m-label {
										display: inline-block;
										margin: 0 10px;

										&.v-enter-active,
										&.v-leave-active {
												transition-duration: .3s;
												transition-property: transform;
												transition-timing-function: ease;
										}

										&.v-enter-from,
										&.v-leave-to {
												transform: translateX(320px);
										}
								}
						}

						@for $i from 1 through 10 {

								&:nth-child(#{$i}){
										.m-label {
												transition-delay: $i * .06s;
										}
								}
						}
				}
		}
}

.ltr {
		nav.side-menu {

				:deep(ul.middle-menu) {
						/*border-bottom: solid 1px #fff;*/
						li {
								/*border-top: solid 1px #fff;*/
								a {
										.m-label {
												&.v-enter-from,
												&.v-leave-to {
														transform: translateX(-320px);
												}
										}
								}
						}
				}

				.bottom-area {
						:deep(.contact-btn.fp-icon-button) {
								&.v-enter-from {
										transform: translateX(320px);
								}
								&.v-leave-to {
										transform: translateX(-320px);
								}
						}
				}
		}
}
</style>
