<template>
    <div class="page model-apartments fade-in">
				<div class="filter-list flex flex-column">
						<apartments-filter 
							@filter="filterChanged"
							@buildingChanged="buildingChanged"
							></apartments-filter>
						<template v-if="isSideMenu">
								<apartments-table
										v-if="filterOptions.apartments_list_layout === 'table'"
										:apartments="filteredProducts"
										:current-apt="apartmentsPageCurrent"
										:loading="loading"
										@apartment-clicked="openApartment"
								></apartments-table>
								<apartments-list
										v-else-if="filterOptions.apartments_list_layout === 'blocks'"
										:apartments="filteredProducts"
										:current-apt="apartmentsPageCurrent"
										:loading="loading"
										scroll="vertical"
										:show-compare="false"
										@apartment-clicked="openApartment"
								></apartments-list>
						</template>
				</div>
				<transition>
						<div v-if="apartmentsPageCurrent" class="apartment-details-pane">
								<apartment-modal
										:apartment="apartmentsPageCurrent"
										:show-buttons="true"
										:show-link="false"
										:navigate-on-button-click="true"
										:show-thumbnail="showThumbnail"
										:next-apartment="nextApartmentPage"
										:previous-apartment="previousApartmentPage"
										@close="apartmentsPageCurrent = null"
										@apartment-clicked="navigateToApartment"
										@previous-apartment-clicked="openApartment"
										@next-apartment-clicked="openApartment"
								></apartment-modal>
						</div>
				</transition>
    </div>
</template>

<script>
import ApartmentsFilter from "@/components/ApartmentsFilter.vue"
import ApartmentsList from "@/components/ApartmentsList.vue"
import {mapGetters} from "vuex"
import ApartmentModal from "@/components/ApartmentModal.vue"
import ApartmentsTable from "@/components/ApartmentsTable.vue"

export default {
    name: "ModelApartmentsPage",
    components: {ApartmentsTable, ApartmentModal, ApartmentsList, ApartmentsFilter},
    computed: {
				apartmentsPageCurrent: {
						get(){
								return this.$store.getters.apartmentsPageCurrent
						},
						set(apartmentsPageCurrent){
								this.$store.commit('apartmentsPageCurrent', apartmentsPageCurrent)
						}
				},
				showThumbnail(){
						return this.apartmentThumbnailDisplay && this.apartmentThumbnailDisplay.indexOf("apartments_page") > -1
				},
				filteredProducts(){
						return Object.entries(this.products)
								.map(e => e[1])
								.sort(this.productsSortingFunc)
								.filter((product) => {
								let passedFilters = true;
								if(this.filter){
										if(this.filter.building){
												if(product.type_building){
														if(product.type_building.id){
																passedFilters = `${this.filter.building}` === `${product.type_building.id}`
														}
														if(!passedFilters){
																if(product.type_building.name){
																		passedFilters = `${this.filter.building}` === `${product.type_building.name}`
																}else{
																		passedFilters = false;
																}
														}
												}
										}
										if(!passedFilters){
												return false;
										}
										if(this.filter.type){
												if(product.type_id){
														passedFilters = `${this.filter.type}` === `${product.type_id}`
												}else{
														passedFilters = false;
												}
										}
										if(!passedFilters){
												return false;
										}
										if(this.filter.rooms){
												if(product.type_rooms){
														passedFilters = `${this.filter.rooms}` === `${product.type_rooms}`
												}else{
														passedFilters = false;
												}
										}
										if(!passedFilters){
												return false;
										}
										if(this.filter.quarter){
												if(product.type_quarter){
														passedFilters = `${this.filter.quarter}` === `${product.type_quarter}`
												}else{
														passedFilters = false;
												}
										}
										if(!passedFilters){
												return false;
										}
										if(this.filter.plot){
												if(product.type_plot){
														passedFilters = `${this.filter.plot}` === `${product.type_plot}`
												}else{
														passedFilters = false;
												}
										}
										if(!passedFilters){
												return false;
										}
										if(this.filter.floor){
												if(typeof(product.floor) !== 'undefined'){
														passedFilters = `${this.filter.floor}` === `${product.floor}`
												}
												if(!passedFilters){
														if(product.floor_name){
																passedFilters = `${this.filter.floor}` === `${product.floor_name}`
														}else{
																passedFilters = false;
														}
												}
										}
								}
								return passedFilters;
						});
				},
				...mapGetters([
						'isSideMenu',
						'currentProjectId',
						'filterOptions',
						'apartmentPageEnabled',
						'apartmentThumbnailDisplay',
						'products',
						'showApartments3DModel',
						'productsSortingFunc'
				])
    },
    data(){
        return {
            filter: null,
            loading: false,
						nextApartmentPage: null,
						previousApartmentPage: null
        }
    },
    methods: {
        // loadProject(to){
        //     const id = to.params.id ? parseInt(`${to.params.id}`, 10) : null
        //     //this.$store.commit('selectProject', id)
				// 		this.$store.dispatch('changeProject', {id})
        // },
        /*async searchProducts(){
            if(this.filter || Object.keys(this.products).length <= 1){
                this.loading = true
                this.filteredProducts = await this.$store.dispatch('searchProducts', {filter: {...this.filter, status: 'publish'}})
                this.loading = false
            }else{
								const products = await this.$store.dispatch('searchProducts')
                this.filteredProducts = [];
								products.forEach((product) => {
										if(product.status === 'publish'){
												this.filteredProducts.push(product)
										}
								})
            }
        },*/
        async filterChanged(filter){
            this.filter = filter
            //await this.searchProducts()
        },
		openApartmentByTitle(title){
				const apartment = Object.entries(this.products).map(entry => entry[1]).find((product) => {
						return product.title === title
				})
				if(apartment){
						this.openApartment(apartment, false)
				}
		},
		buildingChanged(apt, broadcast = true){
				if(this.isSideMenu){
					// console.log("building", building)
						if(broadcast){
								this.broadcastAction({
										action: 'show-building',
										building: apt.building
								})
						}
						
						
				}
		},
		openApartment(apt, broadcast = true){
				if(this.isSideMenu){
						if(broadcast){
								this.broadcastAction({
										action: 'show-apartment',
										apartment: apt
								})
						}
						if(this.apartmentsPageCurrent){
								if(apt.id === this.apartmentsPageCurrent.id){
										this.closeApartment()
								}else{
										this.apartmentsPageCurrent = null
										setTimeout(() => {
												this.apartmentsPageCurrent = apt
										}, 300)
								}
						}else{
								this.apartmentsPageCurrent = apt
						}

						const keys = Object.keys(this.filteredProducts)
						const index = keys.indexOf(`${apt.id}`)
						if(index > -1){
								const nextIndex = index + 1
								const previousIndex = index - 1
								if(keys[nextIndex]){
										this.nextApartmentPage = this.filteredProducts[keys[nextIndex]]
								}else{
										this.nextApartmentPage = null
								}
								if(keys[previousIndex]){
										this.previousApartmentPage = this.filteredProducts[keys[previousIndex]]
								}else{
										this.previousApartmentPage = null
								}
						}
				}else{
					this.navigateToApartment(apt)
				}
		},
		closeApartment(){
				this.apartmentsPageCurrent = null
				this.nextApartmentPage = null
				this.previousApartmentPage = null
		},
		navigateToApartment(apt){
				if(this.apartmentPageEnabled && apt.type_show_page){
					this.$router.push({name: 'apartment', params: {projectId: this.currentProjectId, apartmentId: apt.id}})
				}
		},
		listenFramebus(){
				this.$frameBus.on('message', this.onFramebusMessage)
		},
		unListenFramebus(){
				this.$frameBus.off('message', this.onFramebusMessage)
		},
		onFramebusMessage(data){
				// console.log('onFramebusMessage',{data})
				const {action} = data
				if(action){
						switch(action){
								case 'open-apartment':
										if(data["apartmentTitle"]){
												this.openApartmentByTitle(data["apartmentTitle"])
										}
										break
								case 'close-apartment':
										this.closeApartment()
										break
						}
				}
		},
		broadcastAction(data){
				// console.log('broadcastAction', {data})
				if(this.$frameBus){
						this.$frameBus.emit('message', data);
				}
		}
    },
		/*beforeRouteEnter(to, from, next){
        next(vm => {
            //vm.loadProject(to)
            vm.searchProducts()
        })
    },*/
    /*beforeRouteUpdate(){
        this.searchProducts()
    },*/
		mounted() {
				console.log('ModelApartmentsPage')
				this.listenFramebus()
		},
		unmounted() {
				this.unListenFramebus()
		}
}
</script>

<style lang="scss">
    .page.model-apartments {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;

				.apartments-filter {
						flex-grow: 1;

						&.v-enter-active,
						&.v-leave-active {
								transition: transform 1s ease;
						}

						&.v-enter-from,
						&.v-leave-to {
								transform: translateX(500px);
						}
				}

				.apartments-list {
						margin-top: 1rem;
				}

				.apartment-details-pane {
						&.v-enter-active,
						&.v-leave-active {
								transition: transform .3s ease;
						}

						&.v-enter-from,
						&.v-leave-to {
								transform: translateX(-500px);
						}
				}

				.apartments-table {
						border-top: solid 1px #fff;
						padding: 5px;
						width: 100%;
				}
    }

		.is-desktop,
		.is-tablet {
				.page.model-apartments {
						.apartment-details-pane {
								position: absolute;
								left: 0;
						}
				}
		}

		.is-mobile {
				.page.model-apartments {
						flex-flow: column;
				}
		}

		.fp-side-menu-wrapper + .fp-app-wrapper .page.model-apartments {

				.filter-list {
						height: 100vh;
						position: absolute;
						right: 0;

						.apartments-table {
								flex-grow: 1;
						}
				}

				.apartments-nav-arrows {
						margin-left: 10px;
						margin-right: 10px;
				}

				.apartment-modal {
						border: none;
						border-radius: 0;
						backdrop-filter: blur(5px);
						background-color: rgba(0, 0, 0, 0.8);
						background-image: none;
						margin: 10px 10px 50px;

						.modal-content {
								flex-grow: 0;
						}
				}

				.apartments-compere {
						position: absolute;
						left: auto;
						top: auto;
						right: 310px;
						bottom: auto;
						margin-left: auto;
						align-self: flex-start;
						transition: transform .5s ease;
						transform: rotate(0);
						transform-origin: 100% 100%;

						&.rotate {
								transform: rotate(-90deg) translateX(90px);
						}
				}
		}

		.ltr .fp-side-menu-wrapper + .fp-app-wrapper .page.model-apartments {
				.filter-list {
						right: auto;
						left: 0;
				}
		}

		.ltr .page.model-apartments {

				.apartment-modal {
						margin-right: 10px;
						margin-left: 0;
				}

				.apartment-details-pane {

						&.v-enter-from,
						&.v-leave-to {
								transform: translateX(500px);
						}
				}
		}
</style>
