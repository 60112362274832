<template>
    <div class="mobile-header" v-if="isMobile" ref="mobileHeader">
				<div class="mobile-header-content">
						<fp-icon-button @click="toggleMenu" symbol="hamburger"></fp-icon-button>
						<main-logo></main-logo>
						<fp-icon-button v-if="mobileContactFormButtonLocation === 'top_bar'" class="contact-btn" animated="call-phone" state="hover-phone-ring" @click="$store.commit('toggleContactModal')"></fp-icon-button>
						<div class="mobile-menu" v-if="mobileMenuOpen" @click="menuClick">
								<close-btn></close-btn>
								<menu-bar-middle></menu-bar-middle>
								<menu-bar-left :show-labels="true"></menu-bar-left>
						</div>
				</div>
				<div id="mobileHeaderBottom"></div>
    </div>
</template>

<script>
import MainLogo from "@/components/MainLogo";
import FpIconButton from "@/components/buttons/FpIconButton";
import {mapGetters} from "vuex";
import MenuBarLeft from "@/components/MenuBarLeft";
import CloseBtn from "@/components/buttons/CloseBtn";
import MenuBarMiddle from "@/components/MenuBarMiddle.vue";
export default {
    name: "MobileHeader",
    components: {MenuBarMiddle, CloseBtn, MenuBarLeft, FpIconButton, MainLogo},
    computed: {
        ...mapGetters(['isMobile', 'mobileMenuOpen','mobileContactFormButtonLocation'])
    },
		watch: {
				isMobile(){
						this.updateHeight()
				}
		},
    methods: {
        menuClick(){
            setTimeout(() => {
                this.$store.commit('mobileMenuOpen', false)
            }, 100)
        },
        toggleMenu(){
            this.$store.dispatch('toggleMobileMenu')
        },
				updateHeight(){
						this.$nextTick(() => {
								if(this.$refs.mobileHeader){
										this.$store.commit('mobileHeaderHeight', this.$refs.mobileHeader.clientHeight)
								}
						})
				}
    },
		mounted() {
				this.updateHeight()
		}
}
</script>

<style scoped lang="scss">
.mobile-header {
		position: relative;
		z-index: 11;
    border-bottom: solid 1px #898989;

		.mobile-header-content {
				height: 57px;
				background-color: #000;
				display: flex;
				align-items: center;
				padding: 0 20px;
		}

		#mobileHeaderBottom {
				position: absolute;
				top: 100%;
				right: 0;
				left: 0;
				width: 100vw;

				:deep(.apt-buttons) {
						display: flex;
						overflow-x: auto;
						overflow-y: hidden;
						gap: 15px;
						background-color: #000;
						padding: 0;

						button {
								margin-bottom: .5rem;
						}
				}

				:deep(nav.footer-tabs) {
						background-color: #000;
						display: flex;
						gap: 15px;
						padding-bottom: 9px;
						z-index: 10;
						overflow-x: auto;
						margin-right: 0;
						margin-left: 0;

						.btn {
								min-width: 0;
								padding-right: 15px;
								padding-left: 15px;
								background-color: #000;
								flex-shrink: 0;

								&:first-child {
										margin-right: auto;
								}

								&:last-child {
										margin-left: auto;
								}
						}
				}
		}

    .mobile-menu {
        position: absolute;
        top: 0;
        z-index: 10;
        background-color: #000;
        border-left: solid 1px #919698;
        width: 260px;
        max-width: 100%;
        right: 0;
        border-radius: 0 0 0 5px;
				padding: 50px 10px 30px;
        border-bottom: solid 1px #919698;

        :deep(.close-btn) {
            position: absolute;
            top: 15px;
            right: 15px;
        }

				:deep(ul) li {
						margin-bottom: 10px;
				}

        :deep(ul) li button,
				:deep(ul) li a {
						display: flex;
						padding: 0;
						gap: 5px;
						line-height: 1;
						font-size: 1rem;
						align-items: center;

            &:hover,
            &:focus {
                background-color: #242424;
                border-left-color: #01EADD;
            }

						> svg {
								width: 32px;
								height: 25px;
						}
        }
    }

    :deep(.main-logo){
        margin-right: auto;
        margin-left: auto;
    }
}

.ltr .mobile-header {
		.mobile-menu {
				right: auto;
				left: 0;

				:deep(.close-btn) {
						left: 15px;
						right: auto;
				}
		}
}
</style>
