<template>
		<!--<span v-if="apartment.type_rooms">{{$t('דירה')}} {{apartment.type_rooms}} {{$t('חדרים')}} | </span>{{$t('דגם')}} {{apartment.type}}-->
		<span v-if="apartment_page_title_format" v-bind="$attrs">{{headerTitle}}</span>
		<span v-else>&nbsp;</span>
		<apartment-fav-button v-if="showFavsButton" :apt-id="apartment.id"></apartment-fav-button>
</template>

<script>
import {mapGetters} from "vuex";
import ApartmentFavButton from "@/components/buttons/ApartmentFavButton";

export default {
		name: "ApartmentTitle",
		components: {ApartmentFavButton},
		props: {
				apartment: {
						required: true
				},
				showFavsButton: {
						required: false,
						type: Boolean,
						default: true
				}
		},
		computed: {
				apartment_page_title_format(){
						if(this.filterOptions && this.filterOptions.apartment_page_title_format){
								return this.$store.getters.settings.apartments_filter.options.apartment_page_title_format
						}
						return null
				},
				headerTitle(){
						let titleTemplate = this.apartment_page_title_format
						if(!titleTemplate){
								return null
						}
						const variables = [
								"דירה",
								"טיפוס",
								"בניין",
								"חדרים",
								"קומה",
								"שכונה",
								"מגרש",
						]
						variables.forEach((variable) => {
								let text = null;
								switch (variable){
										case "דירה":
												text = this.apartment.title
												break
										case "טיפוס":
												text = this.apartment.type
												break
										case "בניין":
												text = this.apartment.type_building ? this.apartment.type_building.name : null
												break
										case "חדרים":
												text = this.apartment.type_rooms
												break
										case "קומה":
												text = this.apartment.floor
												break
										case "שכונה":
												text = this.apartment.type_quarter ? this.apartment.type_quarter.name : null
												break
										case "מגרש":
												text = this.apartment.type_plot ? this.apartment.type_plot.name : null
												break
								}

								if(text !== null){
										text = `${text}`
										const rex = new RegExp(`\\[${variable}]`, 'g')
										titleTemplate = titleTemplate.replace(rex,   `${text}`)
								}
						})
						return titleTemplate
				},
				...mapGetters(['filterOptions'])
		}
}
</script>

<style scoped>

</style>
