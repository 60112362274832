<template>
    <transition appear>
        <div class="contact-modal" v-if="show" :class="{'middle-modal':fields && fields.length > 2, 'apartment-only': apartmentOnly, 'has-bottom-bar': hasBottomBar && !contact_bar_static}">
            <close-btn v-if="(!contact_bar_static && isDesktop) || isMobile || isTablet" @click="close"></close-btn>
            <form @submit.prevent="send" ref="contactForm">
                <p class="form-title">{{$t('לפרטים')}}</p>
                <template v-if="fields">
                    <fp-input v-for="(field, fieldIndex) in fields" :key="fieldIndex"
                              :name="field.name"
                              v-model="formData[field.name]"
                              :type="field.type"
                              :placeholder="field.label"
                              :disabled="loading"
                              :required="field.required"
                    ></fp-input>
                </template>
                <template v-else>
                    <fp-input v-model="formData['name']" name="name" :disabled="loading" :placeholder="$t('שם')" required></fp-input>
                    <fp-input v-model="formData['phone']" name="phone" :disabled="loading" :placeholder="$t('טלפון')" required></fp-input>
                </template>
								<div v-if="terms_link && terms_link.url" class="checkbox-wrapper">
										<fp-checkbox v-model="formData.terms_approval" name="terms_approval" :inline="true">{{$t('אישור')}}</fp-checkbox>
										<router-link :target="terms_link.target" :to="{name: 'terms'}">{{ $t('תקנון') }}</router-link>
								</div>
                <fp-button type="submit" class="submit-btn" :disabled="loading" variant="primary-outline">{{$t('שלח')}}</fp-button>
                <div class="alert-message">
                    <loading-data v-if="loading">{{$t('שולח')}}</loading-data>
                    <p v-else-if="alertMessage">{{alertMessage}}</p>
                </div>
            </form>
            <div v-if="contact_details && (contact_details.phone || (contact_details.whatsapp && contact_details.whatsapp.phone))" class="contact-links">
                <tel-link
										v-if="contact_details.phone"
                    :tel="contact_details.phone"
                ></tel-link>
                <span v-if="contact_details.phone && contact_details.whatsapp && contact_details.whatsapp.phone" class="divider"> | </span>
                <tel-link
										v-if="contact_details.whatsapp && contact_details.whatsapp.phone"
										type="whatsapp" :tel="contact_details.whatsapp.phone"
										:message="contact_details.whatsapp.message"
                ></tel-link>
            </div>
						<!--<div v-else class="contact-links">
                <tel-link tel="*6494"></tel-link>
                <span class="divider"> | </span>
                <tel-link type="whatsapp" tel="972507125452" message="I'm interested in info"></tel-link>
            </div>-->
        </div>
    </transition>
</template>

<script>
import CloseBtn from "@/components/buttons/CloseBtn";
import FpButton from "@/components/buttons/FpButton";
import FpInput from "@/components/FpInput";
import TelLink from "@/components/TelLink";
import {mapGetters} from "vuex";
import LoadingData from "@/components/LoadingData";
import FpCheckbox from "@/components/buttons/FpCheckbox";

export default {
    name: "ContactModal",
    components: {FpCheckbox, LoadingData, TelLink, CloseBtn, FpButton, FpInput},
    computed: {
        show(){
            return this.$store.getters.contactModal
        },
        fields(){
            if(this.$store.getters.settings && this.$store.getters.settings.contact_form && this.$store.getters.settings.contact_form.fields){
                return this.$store.getters.settings.contact_form.fields
            }
            return null
        },
        contact_details(){
            if(this.settings && this.settings.contact_form && this.settings.contact_form.contact_details){
                return this.settings.contact_form.contact_details
            }
            return null
        },
				conversion_label(){
						if(this.settings && this.settings.contact_form && this.settings.contact_form.contact_conversion_label){
								return this.settings.contact_form.contact_conversion_label
						}
						return null
				},
				contact_bar_static(){
						if(this.settings && this.settings.contact_form && this.settings.contact_form.contact_bar_static){
								return this.settings.contact_form.contact_bar_static
						}
						return false
				},
				terms_link(){
						if(this.settings && this.settings.contact_form && this.settings.contact_form.terms_link){
								return this.settings.contact_form.terms_link
						}
						return null
				},
				terms_approval_mandatory(){
						if(this.settings && this.settings.contact_form && this.settings.contact_form.terms_approval_mandatory){
								return this.settings.contact_form.terms_approval_mandatory
						}
						return null
				},
				termsPost(){
						if(this.settings && this.settings.global_posts && this.settings.global_posts[`terms_and_conditions_${this.locale}`]){
								return this.settings.global_posts[`terms_and_conditions_${this.locale}`]
						}
						return null
				},
				termsPostName(){
						if(this.termsPost && this.termsPost.post_name){
								return this.termsPost.post_name;
						}
						return this.locale === 'he' ? 'תקנון' : 'terms'
				},
        ...mapGetters(['isMobile','isTablet','isDesktop','settings', 'apartmentOnly', 'hasBottomBar'])
    },
    data(){
        return {
            formData: {
								terms_approval: false
						},
            alertMessage: null,
            loading: false
        }
    },
    methods: {
        async send(){
						if(this.terms_approval_mandatory && this.terms_link && this.terms_link.url && !this.formData.terms_approval){
								alert(this.$t('יש לאשר את התקנון'))
								return
						}
            this.formData.href = window.location.href
            this.loading = true
            const {data, status} = await this.$http.post('contact', this.formData)
            this.loading = false
            if(status === 200 && data.mailSent){
								if(this.conversion_label && typeof(window.gtag) === 'function'){
										window.gtag('event', 'conversion', {
												'send_to': this.conversion_label
										});
								}
								if(typeof(window.dataLayer) !== 'undefined'){
										window.dataLayer.push({'event':'lead'});
								}
                this.formReset()
                this.alertMessage = this.$t('פרטיך התקבלו בהצלחה!');
                setTimeout(() => {
                    this.alertMessage = null;
                }, 3000)
            }
        },
        close(){
            this.$store.commit('toggleContactModal', false)
        },
        formReset(){
            this.formData = {
								terms_approval: false
						}
            if(this.fields){
                this.fields.forEach((field) => {
                    this.formData[field.name] = "";
                })
                this.$refs.contactForm.reset()
            }
        }
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">
    .contact-modal {
        padding: 1.534375rem;
        /*background-color: rgba(0, 0, 0, 0.8);*/
        background-color: #000;
        min-height: 5.1875rem;
        box-sizing: border-box;
        border-radius: 0.375rem;
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.69);
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 15;
        width: 100%;

				&.has-bottom-bar {
						bottom: 85px;
				}

				&.apartment-only {
						bottom: 0;
				}

        &.v-enter-active,
        &.v-leave-active {
            transition: transform .3s ease;
        }

        &.v-enter-from,
        &.v-leave-to {
            transform: translateX(-50%) translateY(300px);
        }

        .alert-message {
            text-align: center;
            position: absolute;
            bottom: 3px;
            left: 0;
            right: 0;
            :deep(.loading-data){
                padding: 0;
            }
        }

        form {
            flex-grow: 1;
            display: flex;
            gap: 1.5625rem;
            justify-content: center;
        }

        .form-title {
            font-size: 1.5625rem;
            font-weight: 700;
            line-height: 1;
            margin: 0;
        }

        .contact-links {
            display: flex;
            flex-flow: row nowrap;
            gap: 1.25rem;
            align-items: center;
        }

        .tel-link {
            font-size: 1.875rem;
            font-weight: 700;
        }

        .divider {
            font-size: 1.875rem;
        }

				.checkbox-wrapper {
						display: flex;
						align-items: center;
						gap: 5px;
				}
    }

    .is-desktop {
        .contact-modal{
            &.middle-modal {
                flex-flow: column;
                align-items: flex-start;
                width: auto;
                bottom: auto;
                top: 50%;
                transform: translate(-50%, -50%);
                padding-bottom: 2rem;

                &.v-enter-active,
                &.v-leave-active {
                    transition: transform .3s ease;
                }

                &.v-enter-from,
                &.v-leave-to {
                    transform: translate(-50%, 100%);
                }

                form {
                    flex-flow: column;
                    align-items: stretch;

                    .submit-btn {
                        align-self: center;
                    }

                    :deep(.form-group) {
                        input {
                            width: 100%;
                        }
                    }
                }

                .contact-links {
                    margin-top: 20px;
                    align-self: center;
                    .divider {
                        display: none;
                    }
                }
            }
        }
    }

    .is-mobile,
    .is-tablet {
        .contact-modal {
            flex-flow: column;
            align-items: flex-start;
            width: auto;
            bottom: auto;
            top: 50%;
            transform: translate(-50%, -50%);
            padding-bottom: 2rem;

						&.v-enter-active,
            &.v-leave-active {
                transition: transform .3s ease;
            }

            &.v-enter-from,
            &.v-leave-to {
                transform: translate(-50%, 100%);
            }

            form {
                flex-flow: column;
                align-items: stretch;

                .submit-btn {
                    align-self: center;
                }

                :deep(.form-group) {
                    input {
                        width: 100%;
                    }
                }
            }

            .contact-links {
                margin-top: 20px;
                align-self: center;
                .divider {
                    display: none;
                }
            }
        }
    }

		.fp-side-menu-wrapper + .fp-app-wrapper {
				.contact-modal {
						border-radius: 0;
						box-shadow: none;
						//background-color: rgba(0, 0, 0, 0.60);
						backdrop-filter: blur(5px);
						background-color: rgba(0, 0, 0, 0.8);

						&.v-enter-from,
						&.v-leave-to {
								transform: translateX(-160%);
						}

						.form-title {
								font-weight: 500;
						}
				}
		}
</style>
