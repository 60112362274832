<template>
    <div class="page entrepreneurs fade-in">
        <transition appear>
            <div class="entrepreneur-modal">
                <close-btn @click.stop="close"></close-btn>
                <div class="entrepreneur-modal-content" v-if="loaded">
                    <div v-if="contentPage">
                        <div class="entrepreneur-intro">
                            {{contentPage.post_title}}
                        </div>
                        <fp-tabs v-model="tab" :tabs="tabsMenu">
														<template v-slot:logo v-if="contentPage.logo">
																<img :src="contentPage.logo.url" :alt="contentPage.logo.alt">
														</template>
												</fp-tabs>
                        <div class="entrepreneur-tabs-content scrollable-y">
														<template v-for="(tabObj, tabIndex) in tabs" :key="tabIndex">
																<div v-if="tabIndex === tab" class="tab">
																	<entrepreneur-info :entrepreneurs="[tabObj]"></entrepreneur-info>
																</div>
														</template>
                        </div>
                    </div>
                    <div v-else>
                        <div class="entrepreneur-intro">
                            בחרנו את הכי טוב בשבילך
                        </div>
                        <fp-tabs v-model="tab" :tabs="{entrepreneur: 'יזם', architect: 'אדריכל'}"></fp-tabs>
                        <div class="entrepreneur-tabs-content scrollable-y">
                            <div class="tab" v-if="tab === 'entrepreneur'">
                                <entrepreneur-info :entrepreneurs="entrepreneurs"></entrepreneur-info>
                            </div>
                            <div class="tab" v-else-if="tab === 'architect'">
                                <entrepreneur-info :entrepreneurs="architects"></entrepreneur-info>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <loading-data></loading-data>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import EntrepreneurInfo from "@/components/EntrepreneurInfo";
import FpTabs from "@/components/FpTabs";
import CloseBtn from "@/components/buttons/CloseBtn";
import LoadingData from "@/components/LoadingData";

export default {
    name: "EntrepreneursPage",
    components: {LoadingData, CloseBtn, FpTabs, EntrepreneurInfo},
    computed:{
        tabsMenu(){
            const tabs = []
            if(this.tabs){
                this.tabs.forEach(tab => {
                    tabs.push(tab.label)
                })
                return tabs
            }
            return {entrepreneur: 'יזם', architect: 'אדריכל'}
        },
        tabs(){
            if(this.contentPage && this.contentPage.tabs){
                return this.contentPage.tabs
            }
            return null
        },
        ...mapState(['entrepreneurs', 'architects']),
        ...mapGetters(['contentPage'])
    },
    data(){
        return {
            tab: 0,
            loaded: false
        }
    },
    methods: {
        close(){
            this.$router.push({name: 'home'})
        },
        async loadPost(postId){
            if(postId){
                await this.$store.dispatch('loadContent', {postId})
            }else{
                await this.$store.dispatch('entrepreneursAndArchitects')
            }
            this.loaded = true
        }
    },
    beforeRouteEnter(to, from, next) {
        const postId = to.params.postId
        next(vm => {
            vm.loadPost(postId)
        })
    },
    beforeRouteUpdate(to, from, next) {
        const postId = to.params.postId
        this.loadPost(postId)
        next()
    },
    beforeRouteLeave(){
        this.$store.commit('currentContentId', null)
    }
}
</script>

<style scoped lang="scss">

.page.entrepreneurs {
    display: flex;
    align-items: center;
    justify-content: center;

		.tab {

				&.v-enter-active,
				&.v-leave-active {
						transition: opacity .5s ease;
				}

				&.v-enter-from,
				&.v-leave-to {
						opacity: 0;
				}
		}
}
.entrepreneur-modal {
    position: relative;
    height: 720px;
    max-height: calc(100vh - 160px);
    width: 1300px;
    max-width: calc(100% - 2rem);
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 87.46%, #000000 93.93%);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    padding-top: 2.875rem;
    display: flex;
    flex-direction: column;

    &.v-enter-active,
    &.v-leave-active {
        transition: transform 1s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: translateY(100vh);
    }

    .close-btn {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .entrepreneur-intro {
        font-size: 1.875rem;
        font-weight: 700;
        text-align: center;
    }
}

.is-desktop,
.is-tablet {
    .entrepreneur-modal-content {
        max-height: 100%;
        overflow-y: auto;
        /* scrollbar design for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            width: 8px;
            background-color: #aaa;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--primary);
        }

        /* scrollbar design for Edge and Firefox */
        & {
            scrollbar-width: thin;
            scrollbar-color: var(--primary) #aaa;
        }
    }
}

.is-mobile {
		.page.entrepreneurs {
				align-items: flex-start;
		}
    .entrepreneur-modal {
        max-width: none;
        max-height: none;
        width: 100vw;
        border: none;
        border-radius: 0;
        height: auto;
        background-color: #000;
        padding-bottom: 3rem;
    }
}

.fp-side-menu-wrapper + .fp-app-wrapper {
		.entrepreneur-modal {
				border-radius: 0;
				border: none;
				background-color: transparent;
				background-image: linear-gradient(to left, rgb(0 0 0 / 80%) 50%,rgb(0 0 0 / 90%) 100%);
				backdrop-filter: blur(5px);
				width: 100%;
				height: 100vh;
				max-height: none;
				max-width: none;
		}

		.entrepreneur-tabs-content {
				margin-top: 30px;
		}

		.tabs-wrapper {
				display: flex;
				align-items: flex-end;
				justify-content: center;
				column-gap: 2rem;
		}
}

</style>
