import {createRouter, createWebHistory} from "vue-router";
import routes from "@/routes";
import store from "@/store";

const router = createRouter({
    history: createWebHistory(),
    routes,
})

const excludeStagesPane = ['apartments','apartments-compare', 'apartment']

router.beforeEach((to, from, next) => {

    if (to.fullPath.indexOf('/fp-admin') !== -1 && to.fullPath.indexOf('/fp-admin/login') === -1) {
        if (!store.state.accessToken) {
            next('/fp-admin/login');
        }
    }

    if (to.fullPath === '/fp-admin/login' || to.fullPath === '/fp-admin') {
        if (store.state.accessToken) {
            next('/fp-admin/apartments');
        }
    }

    const id = to.params.projectId ? parseInt(`${to.params.projectId}`, 10) : null
    if(id){
        store.dispatch('changeProject', {id}).then(() => {}, () => {})
    }

    if(store.getters.ctrl360 === false && to.name === 'home'){
        store.commit('ctrl360', true)
    }else if(store.getters.ctrl360 === true && to.name !== 'home'){
        store.commit('ctrl360', false)
    }

    if(store.getters.stagesPaneShow === true && excludeStagesPane.includes(to.name)){
        store.commit('stagesPaneShow', false)
    }else if(store.getters.stagesPaneShow === false && !excludeStagesPane.includes(to.name)){
        store.commit('stagesPaneShow', true)
    }

    if(to.name === 'apartment-only' && !store.getters.apartmentOnly){
        store.commit('apartmentOnly', true)
    }else if(store.getters.apartmentOnly && to.name !== 'apartment-only'){
        store.commit('apartmentOnly', false)
    }

    next();
});

export default router
