<template>
    <div
				class="apartments-list"
				:class="[`scroll-${scroll}`]"
				@mouseenter="mouseEventsBroadcast(true)"
				@mouseleave="mouseEventsBroadcast(false)"
		>
				<apartments-compere v-if="showCompare" layout="chips"></apartments-compere>
        <transition appear>
            <div ref="apartmentsScroll" class="apartments-scroll" :class="[`scroll-${scroll}`]">
                <template v-if="loading">
                    <loading-data></loading-data>
                </template>
                <template v-else-if="apartmentsList && apartmentsList.length">
                    <transition-group appear>
                        <div v-for="(apt, aptIndex) in apartmentsList" :key="aptIndex"
                             class="apartment"
                             :class="[`floor-${diffClass(apt,aptIndex)}`]"
                        >
                            <div v-if="separate_titles !== 'none'" class="floor-title-vertical">{{verticalTitle(apt)}}</div>
                            <apt-box :apt="apt" :show-thumbnail="showThumbnail" @apartment-clicked="$emit('apartment-clicked', apt, aptIndex)"></apt-box>
                        </div>
                    </transition-group>
                </template>
                <p style="margin: auto; padding: 1rem;" v-else>{{$t('לא נמצאו נתונים')}}</p>
            </div>
        </transition>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import LoadingData from "@/components/LoadingData";
import AptBox from "@/components/AptBox";
import ApartmentsCompere from "@/components/ApartmentsCompere";

export default {
    name: "ApartmentsList",
    components: {ApartmentsCompere, AptBox, LoadingData},
    props: {
        apartments: [Array,Object],
        loading: {
            default: false,
            type: Boolean
        },
				currentApt: {
						default: null
				},
				scroll: {
						default: 'horizontal',
						type: String
				},
				showCompare: {
						default: true,
						type: Boolean
				}
    },
    computed:{
        apartmentsList(){
            if(this.apartments){
                if(this.separate_titles === 'plot'){
                    if(Array.isArray(this.apartments)){
                        return this.apartments
                            .map(apt => Object.assign({}, apt))
                            .sort((a,b) => (a.type_plot.name > b.type_plot.name ? 1 : -1))
                    }
                    return Object.keys(this.apartments)
                        .map(k => Object.assign({}, this.apartments[k]))
                        .sort((a,b) => (a.type_plot.name > b.type_plot.name ? 1 : -1))
                }else if(this.separate_titles === 'quarter'){
                    if(Array.isArray(this.apartments)){
                        return this.apartments
                            .map(apt => Object.assign({}, apt))
                            .sort((a,b) => (a.type_quarter.name > b.type_quarter.name ? 1 : -1))
                    }
                    return Object.keys(this.apartments)
                        .map(k => Object.assign({}, this.apartments[k]))
                        .sort((a,b) => (a.type_quarter.name > b.type_quarter.name ? 1 : -1))
                }else{
                    return this.apartments;
                }
            }
            return null
        },
        separate_titles(){
            if(this.filterOptions && this.filterOptions.separate_titles){
                return this.$store.getters.settings.apartments_filter.options.separate_titles
            }
            return null
        },
        compare_enabled(){
            if(this.filterOptions && this.filterOptions.compare_enabled){
                return this.$store.getters.settings.apartments_filter.options.compare_enabled
            }
            return null
        },
        favorites_enabled(){
            if(this.filterOptions && this.filterOptions.favorites_enabled){
                return this.$store.getters.settings.apartments_filter.options.favorites_enabled
            }
            return null
        },
				showThumbnail(){
						return this.apartmentThumbnailDisplay && this.apartmentThumbnailDisplay.indexOf("filter") > -1
				},
        ...mapState(['currentProjectId', 'products']),
        ...mapGetters(['isDesktop', 'filterOptions','isSideMenu','apartmentThumbnailDisplay'])
    },
    methods: {
        diffClass(apt, aptIndex){
            if(this.separate_titles === 'plot' && apt.type_plot){
                return this.apartmentsList[aptIndex - 1] && this.apartmentsList[aptIndex - 1].type_plot.id === apt.type_plot.id ? 'same':'diff'
            }else if(this.separate_titles === 'quarter' && apt.type_quarter){
                return this.apartmentsList[aptIndex - 1] && this.apartmentsList[aptIndex - 1].type_quarter.id === apt.type_quarter.id ? 'same':'diff'
            }
            return this.apartmentsList[aptIndex - 1] && this.apartmentsList[aptIndex - 1].floor === apt.floor ? 'same':'diff'
        },
        verticalTitle(apt){
            if(this.separate_titles === 'plot' && apt.type_plot){
                return `${this.$t('מגרש')} ${apt.type_plot.name}`
            }else if(this.separate_titles === 'quarter' && apt.type_quarter){
                return `${this.$t('שכונה')} ${apt.type_quarter.name}`
            }else{
                if(apt.floor_name){
                    return `${this.$t('קומת')} ${apt.floor_name}`
                }else if(isNaN(apt.floor)){
                    return `${this.$t('קומת')} ${apt.floor}`
                }else if(apt.floor){
                    return `${this.$t('קומה')} ${apt.floor}`
                }
            }
						return ''
        },
        handleWheelAndDrag(){
            if(this.isDesktop && this.scroll === 'horizontal'){
                const transformScroll = (event) => {
                    if (!event.deltaY) {
                        return;
                    }

                    event.currentTarget.scrollLeft += event.deltaY + event.deltaX;
                    event.preventDefault();
                }

                const apartmentsScroll = this.$refs.apartmentsScroll

                if(apartmentsScroll){
                    apartmentsScroll.style.cursor = 'grab'

                    apartmentsScroll.addEventListener('wheel', transformScroll);

                    let isDown = false;
                    let startX;
                    let scrollLeft;

                    apartmentsScroll.addEventListener('mousedown', (e) => {
                        isDown = true;
                        apartmentsScroll.classList.add('active');
                        startX = e.pageX - apartmentsScroll.offsetLeft;
                        scrollLeft = apartmentsScroll.scrollLeft;
                        apartmentsScroll.style.cursor = 'grabbing'
                    });
                    apartmentsScroll.addEventListener('mouseleave', () => {
                        isDown = false;
                        apartmentsScroll.classList.remove('active');
                        apartmentsScroll.style.cursor = 'grab'
                    });
                    apartmentsScroll.addEventListener('mouseup', () => {
                        isDown = false;
                        apartmentsScroll.classList.remove('active');
                        apartmentsScroll.style.cursor = 'grab'
                    });
                    apartmentsScroll.addEventListener('mousemove', (e) => {
                        if(!isDown) return;
                        e.preventDefault();
                        const x = e.pageX - apartmentsScroll.offsetLeft;
                        const walk = (x - startX) * 3; //scroll-fast
                        apartmentsScroll.scrollLeft = scrollLeft - walk;
                    });
                }
            }
        },
        mouseEventsBroadcast(enter){
                if(this.$frameBus){
                        if(enter){
                                this.$frameBus.emit('message', {
                                        action: 'mouse-enter-apartment-list'
                                })
                        }else{
                                this.$frameBus.emit('message', {
                                        action: 'mouse-leave-apartment-list'
                                })
                        }
                }
        }
    },
    mounted() {
        this.handleWheelAndDrag()
    }

}
</script>

<style scoped lang="scss">

    .apartments-list {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 87.46%, #000000 93.93%);
        border-top: solid 3px var(--primary);
        border-bottom: solid 1px #fff;
        padding: 2rem 0 0.9375rem 0;
        position: relative;

        .apartments-compere {
            position: absolute;
            left: -2px;
        }

        .apartments-scroll {
            overflow-x: hidden;
            overflow-y: auto;
            display: block;

            &.v-enter-active,
            &.v-leave-active {
                transition: transform 1s ease;
            }

            &.v-enter-from,
            &.v-leave-to {
                transform: translateY(500px);
            }

            /* scrollbar design for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
                background-color: #aaa;
            }

            &::-webkit-scrollbar-thumb {
                background: var(--primary);
                height: 8px;
            }

            /* scrollbar design for Edge and Firefox */
            & {
                scrollbar-width: thin;
                scrollbar-color: var(--primary) #aaa;
            }
        }

				&.scroll-horizontal {

						.apartments-scroll {
								display: flex;
								overflow-x: auto;
								overflow-y: hidden;
								flex-flow: row nowrap;
						}
				}

				&.scroll-vertical {
						overflow-y: hidden;
						padding: 0;
						flex-grow: 1;

						.apartments-scroll {
								height: 100%;
								padding: 0 1rem 1rem;
						}
				}

        .apartment {
            display: flex;
            flex-flow: row nowrap;
            flex-shrink: 0;
            overflow: hidden;

            &.v-enter-active,
            &.v-leave-active {
                transition: transform 1s ease;
            }

            &.v-enter-from,
            &.v-leave-to {
                transform: translateY(500px);
            }
        }

        .floor-title-vertical {
            transform: rotate(-90deg) translate(-100%, 1rem);
            transform-origin: top left;
            font-size: 1.875rem;
            color: #686868;

            &::after {
                content: "";
                width: 145px;
                height: 1px;
                background-color: #686868;
                position: absolute;
                right: 0;
                top: 2.5rem;
            }
        }

				.scroll-vertical {

						.apartment {
								flex-flow: column;
								align-items: flex-start;

								&.floor-same {
										margin: 15px 0;
								}
						}

						.floor-title-vertical {
								transform: none;
								transform-origin: initial;
								margin-bottom: 20px;
								margin-top: 25px;
								position: relative;
						}
				}

        .apartment.floor-same {
            margin-right: 15px;
        }

        .apartment.floor-same .floor-title-vertical{
            display: none;
        }

    }

    .is-mobile {
        .apartments-list {
            margin-top: 0;
            display: flex;
            flex-flow: column-reverse;
            border-top: none;
            position: relative;
            background-color: #000;
            padding-bottom: 6rem;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 20px;
                right: 20px;
                height: 1px;
                background-color: #fff;
            }

            .apartments-compere {
                position: relative;
                left: auto;
                bottom: auto;
            }

            .apartments-scroll,
            .apartment {
                flex-flow: column;
                align-items: flex-start;
            }

            .apartments-scroll {
                padding: 20px;
            }

            .apartment {
                &.floor-same {
                    margin: 15px 0;
                }
            }

            .floor-title-vertical {
                transform: none;
                transform-origin: initial;
                margin-bottom: 20px;
                margin-top: 25px;
                position: relative;

                &::after {
                    top: 100%;
                    margin-top: 5px;
                }
            }

            .apartment:first-child .floor-title-vertical {
                margin-top: 0;
            }
        }
    }

		.fp-side-menu-wrapper + .fp-app-wrapper {
				.apartments-list {
						margin-top: 0;
				}
		}

</style>
