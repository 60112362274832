<template>
	<div class="language-switcher" v-if="languages && languages.length > 1">
			<template v-for="(lang, langIndex) in languages" :key="langIndex">
					<a
						v-if="locale !== lang.value"
						href="javascript:"
						@click="setLocale(lang.value)"
					>
						<img v-if="lang.value === 'he'" src="../assets/flags/he.svg" :alt="lang.label">
						<img v-else-if="lang.value === 'en'" src="../assets/flags/en.svg" :alt="lang.label">
						<img v-else-if="lang.value === 'fr'" src="../assets/flags/fr.svg" :alt="lang.label">
					</a>
			</template>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
		name: "LanguageSwitcher",
		computed: {
				languages(){
					if(this.settings && this.settings.languages){
							return this.settings.languages
					}
					return null
				},
				...mapGetters(['settings','locale'])
		},
		methods: {
				...mapActions(['setLocale'])
		},
		mounted() {
		}
}
</script>

<style scoped lang="scss">
.language-switcher {
		display: flex;
		justify-content: center;
		gap: 10px;
		img {
				width: 20px;
		}
}
</style>
