<template>
    <div class="content-modal">
        <div class="content-modal-header">
            <close-btn v-if="isMobile" @click="close"></close-btn>
            <fp-icon-button v-else class="back-btn" symbol="angel-right" @click="close"></fp-icon-button>
            <slot name="header" class="flex-grow-1"></slot>
        </div>
        <div class="content-modal-content scrollable-y">
            <slot></slot>
        </div>
				<div class="content-modal-footer">
						<teleport v-if="isMobile" to="#mobileHeaderBottom">
								<transition :duration="800" appear name="footer">
									<slot v-if="$slots.footer" name="footer"></slot>
								</transition>
						</teleport>
						<template v-else>
								<transition :duration="800" appear name="footer">
										<slot v-if="$slots.footer" name="footer"></slot>
								</transition>
						</template>
				</div>
    </div>
</template>

<script>
import FpIconButton from "@/components/buttons/FpIconButton";
import {mapGetters} from "vuex";
import CloseBtn from "@/components/buttons/CloseBtn";
export default {
    name: "ContentModal",
    components: {CloseBtn, FpIconButton},
		computed: {
				...mapGetters(['isMobile'])
		},
    methods: {
        close(){
            this.$emit('close')
        }
    }
}
</script>

<style scoped lang="scss">
    .content-modal {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.86) 0%, #000000 100%);
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 5px;
        position: relative;
        flex-grow: 1;
        max-width: 100vw;
				display: flex;
				flex-flow: column;

        .content-modal-header {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            padding-right: 1.875rem;
            padding-left: 1.875rem;
            min-height: 50px;
        }

        .content-modal-content {
            padding: 1rem 1.875rem 1.875rem;
            flex-grow: 1;
        }

				.content-modal-footer {
						position: absolute;
						bottom: 35px;
						right: 0;
						left: 0;
						z-index: 10;
				}

        &.full .content-modal-content,
        &.full .content-modal-header {
            padding: 0;
        }

        &.full:not(.tabs) .content-modal-header {
            position: absolute;
            top: 0;
            z-index: 10;
						width: auto;

            button.back-btn {
                width: 42px;
                height: 41px;
                background-color: #000;
                justify-content: center;
                margin: 20px;
                border-radius: 10px;
                border: solid 1px #fff;
            }
        }

        :deep(.iframe) {
            position: relative;
            height: 80vh;
            iframe {
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
            }
        }
    }

    .is-mobile {
        .content-modal {
            width: 100vw;
            max-width: none;
            border-radius: 0;
            border: none;
            background-color: #000;
            background-image: none;
						position: fixed;
						top: 57px;
						left: 0;
						right: 0;
						height: calc(100vh - 117px);
						z-index: 100;
						padding-bottom: 50px;

            :deep(.iframe) {
                padding-top: 120%;
                height: auto;
            }

        }
        .content-modal.full {
            .content-modal-header {
                position: relative;
            }
        }
        button.back-btn {
            display: none;
        }
    }

		.fp-side-menu-wrapper + .fp-app-wrapper {
				.content-modal {
						backdrop-filter: blur(5px);
						background-color: rgba(0,0,0,.8);
						background-image: none;
						height: 100vh;

						:deep(.iframe) {
								height: calc(100vh - 50px);
						}
				}
		}
</style>
