<template>
    <div class="fp-radio-group">
        <label class="fp-radio-group-label"><slot></slot></label>
        <div class="group-wrapper">
            <label class="btn-radio" v-for="(option,optInd) in normalizedOptions" :key="optInd" :class="{active: modelValue === option.value}">
                <input type="radio"
                       :checked="modelValue === option.value"
                       :name="name"
                       :value="option.value"
                       @change="change"
                       @click="click"
                >
                <span class="box">{{option.label}}</span>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: "FpRadioGroup",
    props: {
        modelValue: {
            required: false
        },
        options: {
            required: true,
            type: Array
        },
        name: {
            required: true,
            type: String
        },
				mandatory: {
						required: false,
						type: Boolean,
						default: false
				}
    },
    computed: {
        normalizedOptions(){
            if(this.options && Array.isArray(this.options)){
                return this.options.map((option) => {
                    if(typeof(option) === 'string'){
                        return {
                            value: option,
                            label: option
                        }
                    }
                    return option
                })
            }
            return [];
        }
    },
    methods:{
        change(e){
            // console.log(e.target, e.target.value)
            this.$emit('update:modelValue', e.target.value)
        },
        click(e){
            if(!this.mandatory && e.target.value === this.modelValue){
                this.$emit('update:modelValue', null)
            }
        }
    }
}
</script>

<style scoped lang="scss">

    .fp-radio-group {

        .fp-radio-group-label {
            display: block;
            margin-bottom: 0.625rem;
        }

        .group-wrapper {
            background-color: #000;
            border: solid 1px #fff;
            border-radius: 30px;
            min-height: 34px;
            display: inline-flex;
            align-items: stretch;

            .btn-radio {
                min-width: 34px;
                border-radius: 30px;
                border: solid 1px transparent;
                text-align: center;
                line-height: 1;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin: -1px;
                cursor: pointer;
                font-size: 0.9375rem;
                color: #888888;

                &.active {
                    border-color: var(--primary);
                    color: #ffffff;
                }

                input[type="radio"] {
                    display: none;
                }
            }
        }
    }

		//.fp-side-menu-wrapper + .fp-app-wrapper {
				.fp-radio-group {

						.fp-radio-group-label {
								display: block;
								margin-bottom: 0.625rem;
						}

						.group-wrapper {
								background-color: rgba(0, 0, 0, 0.2);
								border: solid 1px var(--primary);
								border-radius: 4px;

								.btn-radio {
										border-radius: 4px;
										color: #fff;
										padding: 6px;

										&.active {
												border-color: var(--primary);
												background-color: var(--primary);
												color: #000;
										}
								}
						}
				}
		//}
</style>
