<template>
    <div class="page content-page">
        <transition appear>
            <div class="content-modal">
                <close-btn @click.stop="close"></close-btn>
                <div class="post-header">
                    <div class="post-title">
												תקנון, תנאי שימוש ומדיניות פרטיות לאתר ״OLYO״
										</div>
                </div>
                <div class="post-content">
										<p>עדכון אחרון: יולי 2023</p>
										<p><strong>וואי סטודיו הפקות בע"מ</strong> <strong> ח.פ 516762424 </strong>("<strong>החברה</strong>") מברכים את בחירתכם לגלוש באתר האינטרנט <strong>OYLO</strong> המופעל בכתובת: <strong>{{siteUrl}}</strong> (<strong>״האתר״</strong>).</p>
										<p>האתר מעניק לגולשים פלטפורמה מקוונת נוחה ובטוחה בה ניתן למצוא מגוון רב של תכנים ומידע רלוונטי בתחום הנדל"ן לרבות, דירות לרכישה, פרויקטים פעילים, פרטי קשר וכיו"ב ("<strong>התכנים</strong>"). האתר מעניק לגולשים אפשרות לרכוש ולשריין תור ל-48 שעות מרגע התשלום עבור התור באתר בהן באפשרות הרוכש להתחיל בהליך רכישת דירה מול נציג מכירות ("<strong>השירותים</strong>"). כמו כן, באפשרות הגולשים להשאיר פרטים באתר ליצירת קשר וקבלת מידע נוסף.</p>
										<p>יובהר, כי השירותים הינם לצורך שריון תור בלבד המאפשר למזמין להגיע ולהתחיל בהליך רכישת דירה והשירותים אינם מהווים עסקה במקרקעין ו/או התחייבות למכירת הדירה ו/או ההצעה/קיבול לרכישת דירה ו/או אופציה במקרקעין לרכישת דירה.</p>
										<p>רק השלמת הליך רכישה מול נציג החברה המוכרת את הדירות הוא שיחייב בנוגע לרכישת דירה ואין ברכישת השירותים משום הבטחה לתנאי ההסכם ו/או הרכישה.</p>
										<ol>
												<li><strong><u>השימוש באתר:</u></strong>
														<ul>
																<li>תקנון ותנאי שימוש אלה מהווים הסכם מחייב בינך, בין אם באופן פרטי ובין באמצעות תאגיד (<strong>״אתה״</strong>) לבין האתר בנוגע לגלישה ושימוש באתר בכל מחשב או מכשיר תקשורת אחר כדוגמת טלפון סלולרי, מחשבי טאבלט וכיו"ב. כמו כן הם חלים על השימוש באתר ו/או באתרים קשורים באמצעות אפליקציה או כל אמצעי אחר. קרא את התקנון ותנאי השימוש בקפידה, שכן הגלישה באתר וביצוע פעולות בו מעידים על הסכמתך לתנאים הכלולים בתקנון ותנאי שימוש אלו (<strong>״התקנון״</strong>).</li>
																<li>האמור בתקנון מתייחס באופן שווה לבני שני המינים והשימוש בלשון זכר ו/או נקבה הוא מטעמי נוחות בלבד.</li>
																<li>הגלישה באתר מותרת בכל גיל. הרכישה באתר מיועדת לבעלי כשירות משפטית מגיל 18 בלבד. רכישה של קטין מתחת לגיל 18 באתר מחייבת אישור הורה או אפוטרופוס.</li>
																<li>ככלל, הגלישה באתר והעיון בו אינם דורשים הרשמה, והוא פתוח לכל גולש. מבלי לגרוע מן האמור לעיל, האתר שומר על זכותו למנוע מגולש אשר לא נרשם לאתר גישה לשירותים מסוימים באתר, מכל סיבה או נימוק שהוא, בשיקול דעתו הבלעדי, ללא צורך בהודעה מוקדמת או התראה ומבלי שיהיה אחראי לנזק כלשהו עקב החלטתו.</li>
																<li>במקרה שייקבע כי הוראה בתקנון זה אינה ניתנת לאכיפה או שהינה חסרת תוקף מטעם כלשהו, לא יהא בכך כדי להשפיע או לפגוע בחוקיותן, תקפותן ואכיפתן של שאר הוראות התקנון.</li>
														</ul>
												</li>
												<li><strong><u>קניין רוחני:</u></strong>
														<ul>
																<li>אלא אם צוין במפורש אחרת, כל זכויות היוצרים והקניין הרוחני באתר, לרבות, עיצובים, תמונות, שמע, וידאו, טקסט, מאגרי מידע, תוכנות, קוד (<strong>״התוכן״</strong>) וכן סמלילים, סימני מסחר וכיו״ב (<strong>״הסימנים״</strong>) הם בבעלות האתר בלבד, או בבעלות צד שלישי, שהרשה לאתר או נתן רישיון לאתר לעשות שימוש על פי דין בתכנים או בסימנים ובכלל זה שותפיו העסקיים של האתר.</li>
																<li>התוכן והסימנים ניתנים כמו שהם ״AS IS״ לשימוש אישי בלבד. אלא אם צוין במפורש אחרת, אין להעתיק, להפיץ, להציג בפומבי, לבצע בפומבי, להעביר לציבור, לשנות, לעבד, ליצור יצירות נגזרות, למכור או להשכיר כל חלק מן הנ"ל, בין ישירות ובין באמצעות או בשיתוף צד שלישי, בכל דרך או אמצעי בין אם אלקטרוניים, מכאניים, אופטיים, אמצעי צילום או הקלטה, או בכל אמצעי ודרך אחרת, בלא קבלת הסכמה בכתב ומראש מהאתר או מבעלי הזכויות האחרים, לפי העניין, ובכפוף לתנאי ההסכמה.</li>
																<li>אם וככל שניתנה הסכמה כאמור, יש להימנע מלהסיר, למחוק או לשבש כל הודעה או סימן בעניין זכויות קניין רוחני, לדוגמה: סימון זכויות היוצרים ,© או סימן מסחר ®, הנלווים לתכנים שיעשה בהם שימוש.</li>
																<li>בתנאי שאתה זכאי לגלוש באתר, האתר מקנה לך רישיון מוגבל לשימוש בו ולהורדת או הדפסת עותק של כל חלק מהתוכן אליו ניתנה גישה ראויה לשימוש אישי בלבד, שאינו מסחרי.</li>
														</ul>
												</li>
												<li><strong><u>הפרת זכויות יוצרים וקניין רוחני:</u></strong>
														<ul>
																<li>אנו מכבדים את זכויות היוצרים והקניין של אחרים. אם אתה מאמין כי מידע או תוכן באתר מפר את זכויות קנייניות השייכות לך, אנא צור קשר באמצעות פרטי ההתקשרות שבתחתית תקנון זה.</li>
														</ul>
												</li>
												<li><strong><u>הצהרות משתמש:</u></strong>
														<ul>
																<li>בטרם השימוש באתר, גולשי ולקוחות האתר מצהירים, מאשרים ומסכימים כי ידוע להם:
																		<ul>
																				<li><strong>שהתכנים המופיעים באתר ניתנים ומסופקים לשם העשרה בלבד וכל פעולה שתעשה בעקבותיהם תעשה על אחריות הגולש; </strong></li>
																				<li><strong>יובהר, כי רכישת השירותים אינה מהווה הסכמת החברה ו/או החברה המוכרת את הדירות להתקשרות בהסכם לרכישת דירה וזה יינתן רק לאחר בדיקה ואישור מצד החברה ו/או החברה המוכרת את הדירות ובכפוף להשלמת הליך רכישה בהתאם להוראות חוק המכר ו/או הדין הרלוונטי.</strong></li>
																				<li><strong>כל פעולה שתעשה בעקבות שימוש בתכנים המוצגים באתר תעשה במשנה זהירות וכי האחריות הבלעדית לכל נזק (חלילה ויקרה), מכל סוג שהוא, תהיה על עושה השימוש בתכנים בלבד.</strong></li>
																				<li><strong>שהתכנים </strong><strong>אינם מובאים כתחליף לייעוץ השקעות, ייעוץ משפטי ו/או פיננסי ו/או מיסויי, ואין לראות בהם ייעוץ של אנשי מקצוע בתחום הנדל"ן ו/או המשפט ו/או המס ו/או ההשקעות ו/או אנשי מקצוע אחרים; </strong></li>
																				<li><strong>באחריות הגולשים ו/או המשתמשים באתר לבדוק באופן פרטי ו/או באמצעות אנשי מקצוע כל פרויקט המוצג באתר ו/או את התכנים באתר בטרם ביצוע עסקה ו/או רכישה, ובאחריות על כך חלה על הגולשים בלבד.</strong></li>
																				<li><strong>האמור באתר ובתכני האתר אינו מובא כתחליף לקבלת יעוץ פרטני מגורם מקצועי, ואינו מתאים לנסיבותיו הספציפיות של כל מקרה ומקרה והוא מהווה מידע כללי בלבד, אשר אינו מהווה ייעוץ. כל פעולה שנעשית על פי המידע באתר הינה על אחריות עושה הפעולה בלבד. </strong></li>
																				<li><strong>שללקוחות האתר לא תהיה כל טענה כלפי האתר,</strong><strong> מנהליו, עובדיו, שותפיו או מי מטעמו והם לא יהיו אחראים, באופן ישיר או עקיף, לכל נזק לרבות, לגוף, לרכוש או לכל נזק אחר או לכל הפסד אחר, אשר יגרם כתוצאה מקבלת תוכן מהאתר ו/או מהמאמרים ו/או משירותי האתר, אפילו אם האתר הזהיר, המליץ או ייעץ לעניין מסוים או על שירות מסוים ובשום מקרה האתר לא יהווה תחליף או יישא באחריות הלקוח</strong><strong> ו/או מקבל השירות. </strong><strong>על הלקוח חלה האחריות וכל סיכון חובה עבור נזק לרכושו ו/או לרכושם של צדדים שלישיים, הנובעים מהשימוש ו/או אי השימוש באתר ו/או בתכנים באתר.</strong></li>
																				<li><strong>האתר בשום מקרה לא יהיה אחראי לנזק שנגרם לגולש האתר באמצעות יצירת קשר עם יזמים או קבלנים או פרויקטים המפורסמים באתר, או עם שותפיו העסקיים של האתר. </strong></li>
																				<li><strong>האתר אינו צד להתקשרות בין יזמים או קבלנים או פרויקטים</strong><strong> המפורסמים באתר</strong><strong> ללקוח והוא פועל אך ורק בכדי לקשר ביניהם. כמו כן, האתר אינו מספק את השירותים שמפורסמים באתר אלא משמש כפלטפורמה מקוונת בלבד, המתווכת ביניהם.</strong></li>
																		</ul>
																</li>
																<li>בעת גלישה באתר, אתה מתחייב ומצהיר כדלקמן: (1) הגלישה באתר והשימוש הם באחריותך הבלעדית; (2) הפרטים שהזנת בעת השארת הפרטים ו/או רכישה באתר הם נכונים, עדכניים, מדיוקים ומלאים; (3) ככל ויהיה בכך צורך, במקרה של שינוי פרטים תעדכנם בפנייה לאתר; (4) הנך בעל כשירות משפטית ואתה מסכים לתנאי התקנון; (5) לא תעשה שימוש באתר באמצעים אוטומטיים או לא אנושיים, בין אם באמצעות BOT, סקריפט או בכל דרך אחרת; (6) לא תעשה שימוש בלתי חוקי באתר; (7) השימוש שלך באתר לא יפר כל חוק או תקנה רלוונטיים.</li>
																<li>האתר רשאי, למנוע מכל גולש שימוש באתר לפי שיקול דעתו המוחלט. מבלי לגרוע מהאמור לעיל, האתר רשאי לחסום גישתו אליו או חלקה אם בעת השארת פרטים באתר נמסרו במתכוון פרטים שגויים, לא עדכניים או לא מלאים.</li>
														</ul>
												</li>
												<li><strong><u>פעילות אסורה באתר:</u></strong>
														<ul>
																<li>אינך רשאי להשתמש באתר אלא למטרות שלשמן הוא נועד. השימוש באתר מותר למטרות פרטיות ואישיות בלבד ואין לעשות בו שימוש למטרות מסחריות למעט כאלו שיאושרו על ידי האתר באופן ספציפי.</li>
																<li>כמשתמש האתר, אתה מסכים שלא:
																		<ul>
																				<li>לאחזר נתונים או תוכן אחר מהאתר כדי ליצור או להרכיב אוסף, מסד נתונים או מדריך ללא אישור מראש ובכתב מהאתר;</li>
																				<li>לעשות כל שימוש בעיצובי האתר;</li>
																				<li>לעשות שימוש לא מורשה באתר, לרבות איסוף כתובות דוא"ל וכיו״ב באמצעים אלקטרוניים או אחרים לצורך שליחת דוא"ל באמצעים אוטומטיים;</li>
																				<li>לעקוף, להשבית או להפריע בדרך אחרת לאבטחת האתר, לרבות שימוש ביישומים המונעים או מגבילים את השימוש או ההעתקה של תוכן כלשהו;</li>
																				<li>להונות או להטעות את האתר ו/או את משתמשיו;</li>
																				<li>לעשות שימוש לא נכון בשירותי התמיכה של האתר או להגיש דוחות כוזבים בנוגע לשימוש באתר;</li>
																				<li>לעשות שימוש אוטומטי במערכת, כגון שימוש בסקריפטים לשליחת הערות או הודעות, או שימוש בכריית נתונים, רובוטים או כלי איסוף וחילוץ נתונים דומים;</li>
																				<li>לנסות להתחזות לאדם אחר ו/או להעביר את פרטי הגישה לאתר לאדם אחר;</li>
																				<li>להשתמש במידע שהתקבל באתר על מנת להטריד, להתעלל או לפגוע באדם אחר;</li>
																				<li>להשתמש באתר כחלק מכל מאמץ להתחרות באתר;</li>
																				<li>לאחזר, לפענח או להנדס לאחור חלק מהאתר, אפשרות באתר או יישום באתר;</li>
																				<li>להטריד, להפחיד או לאיים על כל אחד מעובדי או סוכני האתר;</li>
																				<li>למחוק את זכויות היוצרים או את הודעת הזכויות הקנייניות מכל תוכן או סימן;</li>
																				<li>להעתיק או להתאים את  קוד האתר או חלק ממנו, כולל אך לא רק HTML ,Java Script PHP ,CSS , JSON או קוד אחר;</li>
																				<li>להעלות או להעביר (או לנסות להעלות או להעביר) וירוסים, סוסים טרויאניים, או חומר אחר, כולל שימוש בדואר זבל, אשר יפריע לשימוש באתר;</li>
																				<li>לבצע פעולה שתפגע או תזיק לאתר, בהתאם לשיקול דעתו של האתר;</li>
																				<li>להשתמש באתר באופן שאינו עולה בקנה אחד החוק, התקנות והפסיקה.</li>
																		</ul>
																</li>
																<li>מבלי לגרוע מכל זכות נוספת, כל שימוש באתר תוך הפרה של האמור לעיל עלול לגרום, בין היתר, לסיום או השעיית זכויותיך לשימוש באתר.</li>
														</ul>
												</li>
												<li><strong><u>תוכן צדדים שלישיים:</u></strong>
														<ul>
																<li>האתר רשאי להשתמש בקישורים לאתרים אחרים (<strong>"אתרי צד שלישי"</strong>) וכן במאמרים, תמונות, טקסט, גרפיקה, תמונות, עיצובים, מוסיקה, סאונד, וידאו, מידע, אפליקציות, תוכנה ותכנים או פריטים אחרים השייכים או שמקורם באתרי צד שלישי (<strong>"תוכן של צד שלישי"</strong>). אתרי צד שלישי ותוכן של צד שלישי אינם נחקרים, מנוטרים, או נבדקים על ידי האתר והאתר לא אחראי לתוכן של צד שלישי שפורסם דרך האתר, שהגעת אליו דרך האתר, שזמין דרך האתר או מותקן בו, כולל תוכן, חוות דעת, אמינות, נוהלי פרטיות או מדיניות אחרת של או כלולים באתרי שלישי או בתוכן של צד שלישי. שימוש בקישור או התרת שימוש או התקנה של אתרים של צד שלישי או כל תוכן של צד שלישי כלשהו אינה מרמזת על אישור או אישור לכך על ידינו, והאחריות על כניסה לקישור זה היא על הגולש בלבד, ועליו לבדוק אותו טרם הכניסה אליו.</li>
																<li>האתר לא לוקח אחריות על כל רכישה שתבצע מאתרי צד שלישי או מחברות אחרות אשר יבוצעו ויהיו בתוקף באופן בלעדי בינך לבין הצד השלישי הרלוונטי.</li>
																<li>ייתכן והאתר יגבה תשלום מצדדים שלישיים עבור סקירה ו/או המלצה ו/או ביקורת למוצרים ו/או שירותים המסופקים על ידי צדדים שלישיים.</li>
																<li>ייתכן והאתר יקבל תגמול מצדדים שלישיים כלשהם עבור פרסום מוצרים ו/או שירותים באתר ו/או עבור שיתופי פעולה עם צדדים שלישיים באתר, בין היתר עבור מכירות שבוצעו באמצעות לינקים לרכישה באתרי צד שלישי.</li>
														</ul>
												</li>
												<li><strong><u>ניהול האתר:</u></strong>
														<ul>
																<li>אנו שומרים לעצמנו את הזכות: (1) לפקח על הפרות של תקנון זה; (2) לנקוט בפעולה משפטית כנגד כל מי שמפר את הוראות החוק או הוראות תקנון זה, לשיקול דעתו הבלעדי של האתר, לרבות, ללא הגבלה, דיווח על המשתמש לרשויות אכיפת החוק; (3) לסרב, להגביל גישה, להגביל זמינות, או להשבית (ככל שניתן מבחינה טכנולוגית) כל תרומה שלך לאתר או כל חלק ממנה, לפי שיקול דעתו הבלעדי של האתר וללא הגבלה; וכן (4) לנהל את האתר באופן שיגן על זכויותיו ורכושו ויקל על תפקודו התקין.</li>
														</ul>
												</li>
												<li><strong><u>הרשאה לדיוור, פרסומים ופרסומת:</u></strong>
														<ul>
																<li>גולש שהשאיר פרטים באתר ומצורף לרשימת הדיוור של האתר, מאשר שימוש בפרטיו לצורך קבלת מידע שיווקי, עדכונים ופרסומות שיבצע האתר מעת לעת.</li>
																<li>על גולש שהשאיר פרטים כאמור תחולנה הוראות הדיוור המפורטות בתקנון להלן.</li>
																<li>אין להשאיר פרטים של אדם אחר באתר שלא בהסכמתו ו/או ללא נוכחותו מול המסך בשעת השארת הפרטים ולאחר שהוסברו לו כל תנאי התקנון.</li>
																<li>בעת השארת הפרטים יתבקש הגולש למסור פרטים אישיים כגון: שם פרטי, שם משפחה, טלפון וכן כתובת דואר אלקטרוני פעילה (לשיקול דעתו הבלעדי של האתר). מסירת פרטים חלקיים או שגויים עלולים למנוע את האפשרות להשתמש בשירות ולסכל יצירת קשר במקרה הצורך. במקרה של שינוי פרטים יש לעדכנם באתר.</li>
																<li>מובהר כי אין חובה על-פי חוק למסור פרטים באתר, אולם בלא למוסרם לא ניתן יהיה לקבל תוכן שיווקי ועדכונים מהאתר.</li>
																<li>האתר לא יעשה בפרטים שנמסרו שימוש, אלא בהתאם למדיניות הפרטיות של האתר המהווה חלק בלתי נפרד מהתקנון.</li>
																<li>השארת פרטים באתר ואישור לקבלת תוכן שיווקי כוללת, בין היתר, קבלת תוכן שיווקי, מידע ביחס למבצעים, עדכונים והנחות המוצעים למשתמשים רשומים.</li>
																<li>אישור דיוור (קבלת תוכן שיווקי) כאמור, מהווה את הסכמתו של הגולש למשלוח דברי פרסומת על-פי חוק התקשורת (בזק ושידורים) (תיקון מס' 40) התשס"ח – 2008 (<strong>"חוק התקשורת"</strong>).</li>
																<li>מובהר כי באפשרות משאיר הפרטים להסיר עצמו בכל עת מהדיוור באמצעות לחיצה על "להסרה מרשימת התפוצה" או כל מלל דומה שמופיע בתחתית כל דיוור שנשלח או באמצעות פנייה לאתר בדוא"ל. כל עוד לא הסיר עצמו הנרשם מרשימת הדיוור כאמור, רשאי האתר, בכפוף לחוק התקשורת, להעביר לנרשם דיוור ישיר כאמור.</li>
																<li>האתר רשאי לבטל את רישומו של גולש לרשימת הדיוור לפי שיקול דעתו המוחלט. מבלי לגרוע מהאמור לעיל, רשאי האתר למנוע גלישת גולש ו/או לבטל רישום לרשימת הדיוור, או לחסום גישה אליו בכל אחד מהמקרים הבאים:
																		<ul>
																				<li>אם בעת השארת הפרטים ו/או הרכישה באתר נמסרו במתכוון פרטים שגויים;</li>
																				<li>במקרה שנעשה שימוש באתר לביצוע או כדי לנסות לבצע מעשה בלתי חוקי על-פי דיני מדינת ישראל, או מעשה הנחזה על פניו כבלתי חוקי כאמור, או כדי לאפשר, להקל, לסייע או לעודד ביצועו של מעשה כזה;</li>
																				<li>אם הופרו תנאי התקנון;</li>
																				<li>אם נעשתה על ידי גולש כל פעולה שתמנע מאחרים להמשיך ולהשתמש באתר בכל דרך שהיא.</li>
																				<li>אין לראות במידע בדיוור משום הבטחה לתוצאה כלשהי ו/או אחריות לשירות המוצע בו.</li>
																		</ul>
																</li>
																<li>האתר לא יהיה אחראי לכל נזק (ישיר או עקיף), הפסד, עגמת נפש והוצאות שייגרמו למשאיר פרטים ו/או לצדדים שלישיים כלשהם בעקבות שימוש או הסתמכות על כל תוכן, מידע, נתון, מצג, פרסומת, מוצר, שירות וכד' המופיעים בדיוור. כל הסתמכות כאמור נעשית על-פי שיקול דעתו ואחריותו הבלעדית של משאיר הפרטים.</li>
																<li>הדיוור בכללותו, לרבות כל המידע המופיע בו, מוצע כמות שהוא, ויהיה מדויק ונכון ככל הניתן, ואולם, יתכן והמידע אינו שלם או לחלופין, יתכן ונפלו טעויות טכניות או אחרות במידע.</li>
																<li>הגולש מאשר כי לא תהיה לו כל טענה בקשר למודעות פרסום ו/או פרסומות המוצגות באתר, לרבות בקשר למיקומן באתר. מובהר כי בכל הנוגע למודעות פרסום המוצגות בחסות צד שלישי, לאתר אין כל התערבות בבחירת הפרסומות המוצגות, אמיתות תוכנן וסדר הופעתן.</li>
														</ul>
												</li>
												<li><strong><u>סיום:</u></strong>
														<ul>
																<li>תנאי תקנון זה יישארו בתוקפם בעת השימוש באתר. מבלי לגרוע מכל הוראה אחרת בתקנון זה, האתר שומר על זכותו למנוע מכל גולש את השימוש באתר (לרבות חסימת כתובות IP מסוימות), מכל סיבה או נימוק שהוא (מבלי שיצטרך לספק סיבה או נימוק), בשיקול דעתו הבלעדי, ללא צורך בהודעה מוקדמת או התראה ומבלי שיהיה אחראי לנזק כלשהו עקב החלטתו. כמו כן, האתר רשאי להפסיק את השימוש שלך בו וכל תוכן או מידע שפרסמת בכל עת, ללא אזהרה מראש.</li>
														</ul>
												</li>
												<li><strong><u>שינויים באתר, תקלות והפסקות שירות:</u></strong>
														<ul>
																<li>האתר שומר את הזכות לשנות מעת לעת או להסיר את תוכן האתר מכל סיבה שהיא לפי שיקול דעתו הבלעדית וללא הודעה מוקדמת. האתר אינו מחויב לעדכן מידע או תוכן כלשהו באתר.</li>
																<li>האתר לא יהיה אחראי כלפיך או כלפי צד שלישי כלשהו עבור שינוי, השעיה או הפסקת שירות כאמור.</li>
																<li>האתר אינו מתחייב ששירותי האתר לא יופרעו, יינתנו כסדרם או בלא הפסקות, יתקיימו בבטחה וללא טעויות ויהיו חסינים מפני גישה בלתי מורשית למחשבי האתר או מפני נזקים, קלקולים, תקלות או כשלים - והכל, בחומרה, בתוכנה, בקווי ובמערכות תקשורת אצל האתר או אצל מי מספקיו.</li>
																<li>האתר שומר על זכותו לשנות, בכל עת וללא הודעה מוקדמת, את התנאים וההתניות בתקנון זה החלים על השימוש באתר ושינויים אלו ייכנסו לתוקפם מיד עם הכנסתם לתקנון.</li>
														</ul>
												</li>
												<li><strong><u>הרכישה באתר:</u></strong>
														<ul>
																<li>ניתן לרכוש את השירותים באופן נוח ומאובטח באמצעות האתר.</li>
																<li>מחירים, זמינות ותנאי רכישה אחרים מופיעים בדפי האתר ועשויים להשתנות מעת לעת. האתר שומר לעצמו את הזכות לשנות את מגוון השירותים או להפסיקם או לשנות את המחירים המוצעים באתר ומחוצה לו, ללא הודעה מוקדמת.</li>
																<li>בעת הזמנה באתר, אתה אחראי לספק מידע נכון, מדויק, עדכני ומלא. האתר רשאי לאמת את הפרטים שהוזנו לאחר ביצוע הזמנה, והוא רשאי לבטל או להגביל את ההזמנה בכל עת.</li>
																<li>אם, מכל סיבה שהיא, חברת כרטיס האשראי תסרב להעביר את סכום התשלום בגין רכישה באתר, האתר יהיה רשאי להשהות או להפסיק לאלתר את מתן השירות והוא רשאי לדרוש את התשלום בתוספת עלות שנגרמה לאתר עקב הסירוב (לרבות קנסות מצד שלישי) באמצעים אחרים, לשיקול דעתו של האתר. במידה והאתר יאלץ לנקוט בהליכים משפטיים לגביית יתרות תשלום, אתה מתחייב לשפות את האתר בכל ההוצאות שיגרמו לו, לרבות שכר טרחת עורך דין והוצאות משפט נוספות.</li>
																<li>רכישה באתר כפופה לתנאים ולהגבלות נוספות של חברות האשראי ו/או כל אמצעי תשלום אחר המופיע באתר.</li>
														</ul>
												</li>
												<li><strong><u>מתן השירות: </u></strong>
														<ul>
																<li>רוכש השירותים יוכל להגיע לפגישה עם נציג מכירות לצורך קבלת מידע נוסף ולהשלמת הליך רכישת דירה במועד ובמיקום שיצוין באישור ההזמנה שיקבל הלקוח לכתובת דואר האלקטרוני שלו לאחר ביצוע ההזמנה והרכישה, ובכפוף לאישור האתר.</li>
																<li>מתן השירות יתאפשר בכפוף לשיקול דעתו הבלעדי של האתר והאתר לא יהא אחראי לכל איחור ו/או עיכוב במתן השירות ו/או אי-מתן השירות, כתוצאה מכוח עליון ו/או צדדים שלישיים ו/או מאירועים שאינם בשליטתו, ובכלל זה מלחמה, שביתה או השבתה, פעולות חבלה, הפרעות לסדר הציבורי, מעשה או מחדל של צד שלישי או מגבלות שהוטלו  על ידו, חוקים, תקנות, צווים או הוראות ממשלתיות אחרות, מגבלות ביטחוניות, מגיפה, סגר או נסיבות אחרות שאינן בשליטתו.</li>
														</ul>
												</li>
												<li><strong><u>מדיניות ביטול הזמנות:</u></strong>
														<ul>
																<li>בקשה להחזר, שינוי או ביטול עסקה יועברו לאתר באחד מאמצעי ההתקשרות המופיעים בתחתית התקנון.</li>
																<li><strong>השירותים המוצעים לרכישה באתר מעניקים שריון של תור באופן מיידי ל-48 שעות שלאחר השלמת הרכישה באתר, לכן בשל אופי השירות כאמור, </strong><strong>בהתאם להוראות חוק הגנת הצרכן, התשמ"א-1981</strong><strong>, ש</strong><strong>ינוי ו/או ביטול לאחר תשלום ושריון התור לא יתאפשרו והרוכש לא יהיה זכאי להחזר כספי כלשהו.</strong></li>
																<li>האמור בתקנון זה ובסעיף זה כפוף להוראות חוק הגנת הצרכן, התשמ"א-1981.</li>
														</ul>
												</li>
												<li><strong><u>סמכות שיפוט:</u></strong>
														<ul>
																<li>על תקנון זה והשימוש באתר יחולו אך ורק דיני מדינת ישראל, אולם לא תהיה תחולה לכללי ברירת הדין הבינלאומי הקבועים בהם.</li>
																<li>לבתי המשפט במחוז הדרום תהיה סמכות שיפוט ייחודית בכל עניין הנובע ו/או הקשור לתקנון ו/או למחלוקות משפטיות שיתגלו בינך לבין האתר.</li>
														</ul>
												</li>
												<li><strong><u>דיוק/ תיקונים טכניים ואחרים:</u></strong>
														<ul>
																<li>האתר בכללותו, לרבות כל המידע המופיע בו, מוצע לציבור כמות שהוא, ויהיה מדויק ונכון ככל הניתן, ואולם, יתכן והמידע אינו שלם או לחלופין, יתכן ונפלו טעויות טכניות או אחרות במידע. האתר שומר על זכותו לתקן טעויות ו/או שגיאות ו/או אי דיוקים כאמור ולעדכן את המידע באתר בכל עת וללא הודעה מוקדמת.</li>
														</ul>
												</li>
												<li><strong><u>אחריות </u></strong><strong><u>האתר</u></strong><strong><u>:</u></strong>
														<ul>
																<li><strong>אין</strong> <strong>לראות</strong> <strong>במידע</strong> <strong>המופיע</strong> <strong>באתר</strong> <strong>משום</strong> <strong>הבטחה</strong> <strong>לתוצאה</strong> <strong>כלשהי</strong> <strong>ו/או</strong> <strong>אחריות</strong> <strong>לאופן הפעילויות</strong> <strong>של</strong> <strong>השירותים המסופקים</strong> <strong>בו או המידע שמוצג בו. האתר</strong> <strong>לא</strong> <strong>יהיה</strong> <strong>אחראי</strong> <strong>לשום</strong> <strong>נזק, ישיר</strong> <strong>או עקיף, אשר</strong> <strong>ייגרם</strong> <strong>לגולש</strong> <strong>כתוצאה</strong> <strong>מהסתמכות</strong> <strong>על</strong> <strong>מידע</strong> <strong>המופיע באתר</strong> <strong>ו/או</strong> <strong>בקישורים</strong> <strong>לאתרים</strong> <strong>אחרים</strong> <strong>ו/או</strong> <strong>כל</strong> <strong>מקור</strong> <strong>מידע</strong> <strong>פנימי</strong> <strong>ו/או</strong> <strong>חיצוני</strong> <strong>אחר ו/או</strong> <strong>שימוש</strong> <strong>בשירותים ובמידע </strong><strong>אשר</strong> <strong>מוצגים</strong> <strong>על</strong> <strong>ידו.</strong></li>
																<li><strong>התכנים המופיעים באתר מסופקים על ידי צדדים שלישיים. הסתמכות על התכנים וההתקשרות עם המפרסמים וכותבי התכנים נעשית באחריותו הבלעדית של הלקוח ולא תהיה ללקוח כל טענה כלפי האתר, מנהליו, עובדיו או מי מטעמו והם לא יהיו אחראים, באופן ישיר או עקיף, לכל נזק לרבות, לגוף, לרכוש או לכל נזק אחר או לכל הפסד אחר, אשר יגרם כתוצאה משימוש בתכנים, ברכישת מוצרים ובשירותים המפורסמים באתר, אפילו אם האתר הזהיר, המליץ או ייעץ לעניין מסוים או על שירות מסוים ובשום מקרה האתר לא יהווה תחליף או יישא באחריות הגולש. </strong></li>
																<li>התמונות, הסרטונים, הדמיות וכיו"ב המוצגים באתר הינם לצורכי המחשה בלבד. ייתכנו הבדלים במראה, בגוון, בגודל, וכיו"ב בין המוצג באתר לבין הפרויקט שנבנה ו/או ייבנה במציאות. טעות סופר בתיאור ו/או במחיר לא תחייב את האתר.</li>
																<li>מידע ומצגים אודות פרויקטי הנדל"ן המוצגים באתר ו/או אודות שירותים המוצגים באתר, שמקורם בשותפיו העסקיים של האתר ו/או צדדים שלישיים ששירותיהם מופיעים באתר, הינו באחריותם הבלעדית של משתמשי האתר ו/או השותפים העסקיים כאמור, ועל כן, מובן שלאתר אין כל אחריות בגין מידע מעין זה, ואין האתר ערב למידת הדיוק של מידע זה.</li>
																<li>האתר עושה מאמצים לבדוק את הפרויקטים והתכנים המועברים לפרסום באתר. יחד עם זאת, יודגש ויובהר שהאתר לא יהיה מחויב לעשות בדיקות רקע לפרויקטים ו/או ליזמים ו/או לחברות ששירותיהם מפורסמים באתר וכל התקשרות עימם נעשית על אחריות הגולש בלבד.</li>
																<li>האתר לא יהיה אחראי לכל נזק (ישיר או עקיף), הפסד, עגמת נפש והוצאות שייגרמו לגולשים ו/או לצדדים שלישיים כלשהם בעקבות שימוש או הסתמכות על כל תוכן, מידע, נתון, מצג, תמונה, וידאו, אודיו, פרסומת, מוצר, שירות וכו' המופעים באתר. כל הסתמכות כאמור נעשית על-פי שיקול דעתו ואחריותו הבלעדית של הגולש באתר.</li>
																<li>האתר בשום מקרה לא יהיה אחראי לנזק שנגרם לגולש האתר באמצעות יצירת קשר עם בית עסק או חברה או פרויקט המפורסם באתר, או עם שותפיו העסקיים של האתר.</li>
																<li>האתר אינו צד להתקשרות בין היזמים ו/או החברות ו/או הפרויקטים המפורסמים באתר ללקוח והוא פועל אך ורק בכדי לקשר בין ביניהם. כמו כן, האתר אינו מספק את השירותים שמפורסמים באתר אלא משמש כפלטפורמה מקוונת בלבד, המתווכת ביניהם.</li>
																<li>האתר ממליץ לגולשים באתר לנהוג בתבונה ובזהירות, ולקרוא בעיון את המידע המוצג באתר ובכלל זה את המידע ביחס לשירות עצמו, תיאורו והתאמתו, כמתואר להלן.</li>
																<li>התכנים באתר ניתנים לשימוש כמות שהם (<strong>"AS IS"</strong>). לא ניתן להתאימם לצרכיו של כל אדם ואדם. לא תהיה לגולש כל טענה, תביעה או דרישה כלפי האתר בגין תכונות של התכנים, יכולותיהם, מגבלותיהם ו/או התאמתם לצרכיו.</li>
																<li>האתר בכללותו, לרבות כל המידע המופיע בו, מוצע לציבור כמות שהוא, ויהיה מדויק ונכון ככל הניתן, ואולם, יתכן והמידע אינו שלם או לחלופין, יתכן ונפלו טעויות טכניות או אחרות במידע.</li>
																<li>השימוש באתר ייעשה על אחריותו הבלעדית והמלאה של כל גולש. כל החלטה שתתקבל ביחס לתכנים שיתפרסמו באתר הינה באחריותו המלאה של הגולש.</li>
																<li>האתר אינו מתחייב כי תכנים ושירותים המתפרסמים באתר, יהיו מלאים, נכונים, חוקיים או מדויקים או יהלמו את ציפיותיו ודרישותיו של כל גולש. האתר לא יישא באחריות כלשהי לכל תוצאה שתנבע מהם, או משימוש בהם, או מהסתמכות עליהם, לרבות: (1) שגיאות, טעויות ואי-דיוקים; (2) נזק לגוף או לרכוש, מכל סוג, הנגרם עקב השימוש באתר ו/או בשירותי האתר; (3) הפרעה בגישה לאתר או מהאתר; (4) כל באג, וירוס, סוסי טרויאני וכיו״ב שעלולים להיות מועברים לאתר על ידי צד שלישי כלשהו.</li>
														</ul>
												</li>
												<li><strong><u>מדיניות פרטיות:</u></strong>
														<ul>
																<li>האתר מכבד את פרטיות הלקוחות.</li>
																<li>בנוסף למידע אותו הנך מוסר בעת הרשמתך לאתר, האתר אוסף מידע מסוים אודות המחשב שלך, אשר באמצעותו הנך מבקר ומבצע פעולות באתר. המידע נאסף באופן אוטומטי (לרבות באמצעות שימוש ב "Cookies") והוא עשוי לכלול כתובות IP, סוג דפדפן, זמני גלישה וכניסה לאתר, אופן הגלישה והכלי בו הנך משתמש למטרת הגלישה, פרטים לגבי ספק האינטרנט שלך וכתובות אתרי האינטרנט מהם הגעת (המידע הנמסר בעת ההרשמה והמידע הנאסף על ידי האתר כאמור בפסקה זו יכונו יחד: <strong>"המידע הנאסף באתר"</strong>). המידע הנאסף באתר עשוי לשמש את האתר לצרכים הבאים:
																		<ul>
																				<li>לספק לך שירותים ולשפר את האתר ו/או את השירותים;</li>
																				<li>תפעולו התקין של האתר;</li>
																				<li>לנתח את ולנהל את האתר באופן תקין;</li>
																				<li>שיפור שירות הלקוחות של האתר;</li>
																				<li>ליצירת קשר או לספק לך נתונים בקשר לאתר או לשירות;</li>
																				<li>בכדי לספק לך את המידע שביקשת או מידע נוסף אשר באתר מאמינים שהוא עשוי לעניין אותך, מעת לעת;</li>
																				<li>בכדי להתאים פרסומות ומידע מסחרי על פי העדפותיך האישיות;</li>
																				<li>בכדי ליצור עמך קשר על ידי נציגי האתר בנוגע לשירותים אותם האתר מספק;</li>
																				<li>בכדי לבצע סקרי לקוחות ו/או מחקרי שיווק אשר האתר עורך מעת לעת.</li>
																		</ul>
																</li>
																<li>האתר ו/או מי מטעמו לא יגלו ו/או ימכרו כל מידע הנאסף באתר לכל צד שלישי כלשהו, וזאת למעט למי מטעמם להם הם מספקים את המידע הנאסף באתר אך ורק לצורך מתן שירות והשלמת הליך רכישה שבוצע באתר, ואשר אף הם מחויבים להגנה מלאה על סודיות המידע הנאסף באתר.</li>
																<li>יחד עם זאת, האתר ו/או מי מטעמו יהיו רשאים להעביר מידע הנאסף באתר לצדדים שלישיים (למעט מידע רגיש כגון פרטי אשראי, מספרי תעודות זהות וכיו״ב) בכל אחד מהמקרים הבאים:
																		<ul>
																				<li>ניתנה לכך הסכמתך מראש ובכתב;</li>
																				<li>האתר ו/או מי מטעמו נדרשים על פי חוק להעביר את המידע האמור, למשל, מכוח צו בית משפט;</li>
																				<li>על מנת להגן על זכויותיהם המשפטיות והקנייניות של האתר ו/או מי מטעמו בכל הנוגע לאתר זה;</li>
																				<li>במקרה של מחלוקת משפטית בינך לבין האתר אשר תחייב חשיפת הפרטים;</li>
																				<li>אם תפר את התקנון של האתר או אם תבצע באמצעות האתר, או בקשר כלשהו עם האתר, פעולות מנוגדות או הנחזות כמנוגדות לדין, או כל ניסיון לבצע פעולות כאלה;</li>
																				<li>בכל מקרה שהאתר יסבור, כי מסירת המידע הנאסף נחוצה על מנת למנוע נזק חמור לגוף המשתמש או לרכושו או לגופו או לרכושו של צד שלישי;</li>
																				<li>שיתוף רשתות חברתיות כגון פייסבוק, אינסטגרם ואחרות ומפרסמים נוספים ברשת כגון גוגל, טאבולה ואחרים.</li>
																		</ul>
																</li>
														</ul>
												</li>
										</ol>
										<p>כמו כן, יתכן שהאתר יעביר לצדדים שלישיים מידע סטטיסטי כללי בלבד, שאינו אישי או פרטי, לגבי השימוש באתר, כגון המספר הכולל של המבקרים באתר זה ובכל עמוד של האתר וכן שמות הדומיין של נותני שירות האינטרנט של המבקרים באתר;</p>
										<ul>
												<li>אם האתר ימוזג לתוך פעילות גוף אחר או אם האתר יעבור לבעלות תאגיד אחר ניתן יהיה להעביר לתאגיד החדש את המידע הקיים באתר, אבל רק במקרה שהתאגיד יתחייב לשמור על מדיניות פרטיות זו.</li>
										</ul>
										<ul>
												<li>חשוב לזכור שלא ניתן לערוב במאת האחוזים מפני פעילות עוינת ונחושה מצד גורמים זרים ולכן אין בפעולות אלה בטחון מוחלט והאתר לא מתחייב שהשירותים באתר יהיו חסינים באופן מוחלט מפני גישה בלתי מורשית למידע הנאסף בו.</li>
										</ul>
										<ol start="18">
												<li><strong><u>שיפוי:</u></strong>
														<ul>
																<li>על הגולש לשפות את האתר, עובדיו, מנהליו, שותפיו העסקיים או מי מטעמם בגין כל נזק, הפסד, אבדן רווח, תשלום או הוצאה שייגרמו להם - ובכלל זה שכ"ט עו"ד והוצאות משפט עקב הפרת התקנון.</li>
														</ul>
												</li>
												<li><strong><u>שונות:</u></strong>
														<ul>
																<li>תנאי תקנון זה מהווים את כל ההסכמות וההבנות בנוגע לשימוש באתר. אי מימוש או אכיפה של זכות או הוראה בתקנון זה, לא תחשב כוויתור מצד האתר על מימוש הזכות או אכיפת ההוראה. האתר יהיה רשאי להמחות לאחרים את כל או חלק מזכויותיו ו/או חובותיו בתקנון זה.</li>
														</ul>
												</li>
												<li><strong><u>צרו קשר:</u></strong>
														<ul>
																<li>האתר מקפיד על קיום הוראות החוק ומכבד את זכותם של משתמשי האתר ואחרים לפרטיות ולשם טוב. אם אתה סבור כי פורסם באתר תוכן הפוגע בך מטעם כלשהו, נא פנה אלינו לפי הפרטים שלהלן ואנו נשתדל לטפל בפנייתך בכל ההקדם. פניות כאמור ניתן להעביר באמצעים הבאים:</li>
														</ul>
												</li>
										</ol>
										<p><u>כתובת</u>: דויד המלך 32, קרית גת;</p>
										<p><u>טלפון</u>: 050-4611161;</p>
										<p><u>דוא"ל</u>:  office@studioy.io</p>
										<p>עדכון אחרון: יולי 2023</p>
								</div>
            </div>
        </transition>
    </div>
</template>

<script>
import CloseBtn from "@/components/buttons/CloseBtn";

export default {
    name: "LegalTermsPage",
    components: {CloseBtn},
    computed:{
				siteUrl(){
					return location.host
        }
    },
		methods: {
				close(){
						this.$router.push({name: 'home'})
				}
		}
}
</script>

<style scoped lang="scss">

.content-page {
    display: flex;
    justify-content: center;
    padding: 0 2.6875rem;

    &.v-enter-active,
    &.v-leave-active {
        transition: transform 1s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: translateY(100vh);
    }
}

.content-modal {
    position: relative;
    height: 100vh;
    max-height: calc(100vh - 40px);
    width: 100%;
    max-width: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 87.46%, #000000 93.93%);
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    flex-flow: column nowrap;

    .close-btn {
        position: absolute;
        top: 1.875rem;
        left: 1.875rem;
    }

    .post-header {
        margin-top: 3.75rem;
        margin-bottom: 1.8rem;
        margin-right: 4.0625rem;
        margin-left: 4.0625rem;
        display: flex;
        align-items: center;
        line-height: 1;

        .post-title {
            font-weight: 700;
            font-size: 1.875rem;
        }

        .post-icon + .post-title {
            margin: 0 0.625rem;
        }
    }

    .post-content {
        margin: 0 4.0625rem 6.25rem;
        padding: 0 0.625rem;
        overflow: auto;
				ul,
				ol{
						padding-right: 15px;
						list-style: auto;
						margin-bottom: 10px;
						line-height: 1.2;
						li {
								margin-bottom: 5px;
						}
				}

				p {
						margin-bottom: 10px;
						line-height: 1.2;
				}

        /* scrollbar design for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            width: 8px;
            background-color: #aaa;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--primary);
        }

        /* scrollbar design for Edge and Firefox */
        & {
            scrollbar-width: thin;
            scrollbar-color: var(--primary) #aaa;
        }
    }
}

.is-mobile,
.is-tablet {
    .content-modal {
        max-height: calc(100vh - 190px);

        .post-content {
            margin: 0;
        }
    }
}

.is-mobile {
    .content-page {
        padding: 0;

        .post-content {
            overflow: visible;
        }
    }
    .content-modal {
        height: auto;
        width: 100vw;
        max-width: none;
        max-height: none;
        border-radius: 0;
        border: none;
        background-color: rgba(0, 0, 0, 0.86);
        background-image: none;
        padding-bottom: 6rem;
				padding-right: 15px;
				padding-left: 15px;
    }
}

</style>
