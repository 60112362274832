<template>
	<div class="admin buildings-admin">
			<h3>{{$t('ניהול בניינים')}}</h3>
			<div class="apartments-menu">
					<apartments-table
							:apartments="products"
							@apartment-clicked="openApartment"
					></apartments-table>
					<div class="model-wrapper">
							<model-frame
									width="100%"
									height="100%"
									:show-resize-btn="false"
							></model-frame>
					</div>
			</div>
	</div>
</template>

<script>
import ModelFrame from "@/components/ModelFrame.vue"
import {mapGetters} from "vuex"
import ApartmentsTable from "@/components/ApartmentsTable.vue"

export default {
		name: "BuildingsAdmin",
		components: {ApartmentsTable, ModelFrame},
		computed: {
				...mapGetters(['accessToken','products'])
		},
		methods: {
				listenFramebus(){
						this.$frameBus.on('message', this.onFramebusMessage)
				},
				unListenFramebus(){
						this.$frameBus.off('message', this.onFramebusMessage)
				},
				onFramebusMessage(data){
						const {action} = data
						if(action === 'init'){
								this.$frameBus.emit('message', {
										action: 'i-am-admin',
										token: this.accessToken,
										show_view_actions: true
								})
								console.log('i-am-admin has sent')
						}
				},
				openApartment(apt){
						this.$frameBus.emit('message', {
								action: 'show-apartment',
								apartment: apt
						})
				}
		},
		mounted() {
				this.listenFramebus()
		},
		unmounted() {
				this.unListenFramebus()
		}
}
</script>

<style scoped lang="scss">
.buildings-admin {
		flex-grow: 1;

		.model-wrapper {
				position: relative;
				flex-grow: 1;
		}

		.apartments-menu {
				display: flex;
				height: 90vh;
		}
}
</style>
