import "./style.scss"
import { createApp } from "vue"
import App from "@/App.vue"
import ModelApp from "@/ModelApp.vue";
import store from "@/store"
import router from "@/router"
import i18n from "@/i18n"
import VueAxios from "vue-axios"
import http from "@/http"
import "./lordicons"
import {svgSpritePlugin} from "vue-svg-sprite"
import PerfectScrollbar from "vue3-perfect-scrollbar"
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css"
import "gitart-vue-dialog/dist/style.css"
import { GDialog } from "gitart-vue-dialog"
import { plugin as dialogPlugin } from "gitart-vue-dialog"
import frameBus from "@/framebus";
import ModelApartmentsPage from "@/model-pages/ModelApartmentsPage.vue";

const appContainer = document.getElementById('floorplan-app')
let appType = 'standard'
if(appContainer.dataset && appContainer.dataset.appType && appContainer.dataset.appType.indexOf('<?') === -1){
    appType = appContainer.dataset.appType
}else if(process.env.VUE_APP_TYPE) {
    appType = process.env.VUE_APP_TYPE
}

const mobileMediaQuery = window.matchMedia('(max-width: 992px)')

const app = appType === '3d-model' && !mobileMediaQuery.matches ? createApp(ModelApp) : createApp(App)
if(appType === '3d-model' && !mobileMediaQuery.matches){
    store.commit('isModelApp', true)
    //router.removeRoute('apartments')
    router.addRoute({
        path: '/projects/:projectId/apartments',
        name: 'apartments',
        component: ModelApartmentsPage
    })
}
app.config.globalProperties.$frameBus = frameBus
app.use(svgSpritePlugin, {
    url: require('./assets/icons-sprite.svg'),
    class: 'pf-icon',
})
app.use(VueAxios, http)
app.use(i18n)
app.use(store)
app.use(router)
app.use(PerfectScrollbar)
app.use(dialogPlugin, {})
app.component('GDialog', GDialog)
app.mount(appContainer)

