<template>
		<span v-if="!apartmentOnly" class="fav-button-wrapper">
				<button class="fav-button" @click.stop="toggleFavs(aptId)">
						<svg v-if="inFavs" :style="{fill: color}" :height="size" viewBox="0 0 506.525 506.525" :width="size" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m464.143 67.14c-56.52-56.51-146.47-58.51-202.99-2l-7.46 7.46s-6.92-6.92-7.22-7.21c-27.27-26.81-63.3-41.56-101.6-41.56-38.71 0-75.11 15.08-102.49 42.45-56.51 56.52-56.51 148.47 0 204.99l211.31 211.51 210.45-210.65c56.51-56.52 56.51-148.48 0-204.99zm-49.217 96.024c0-24.734-20.123-44.857-44.857-44.857v-30c41.276 0 74.857 33.581 74.857 74.857z"/></g></g></svg>
						<svg v-else :style="{fill: color}" :height="size" viewBox="0 0 506.53 506.53" :width="size" xmlns="http://www.w3.org/2000/svg"><g><path d="m253.903 482.741-10.829-10.805-200.692-200.71c-56.511-56.515-56.509-148.47.004-204.982 27.377-27.377 63.777-42.455 102.494-42.455s75.117 15.077 102.494 42.455l6.32 6.319 5.462-5.462c56.516-56.515 148.473-56.516 204.988 0 56.513 56.513 56.515 148.468.005 204.983l-200.379 200.396zm-109.023-428.952c-30.704 0-59.57 11.957-81.281 33.667-44.816 44.817-44.818 117.74-.003 162.558l190.099 190.114 189.24-189.256c44.814-44.819 44.813-117.742-.003-162.559-44.818-44.818-117.744-44.817-162.563 0l-26.675 26.676-27.533-27.533c-21.711-21.711-50.577-33.667-81.281-33.667z"/><path d="m444.928 163.128h-30c0-24.734-20.123-44.857-44.857-44.857v-30c41.276 0 74.857 33.581 74.857 74.857z"/></g></svg>
				</button>
				<a v-if="showLinkToFavs" href="javascript:void(0)" @click.stop="navigateToFavorites">{{$t('מועדפים')}}</a>
		</span>
</template>

<script>
import {mapGetters} from "vuex";
export default {
		name: "ApartmentFavButton",
		props: {
				aptId: {
						required: true
				},
				size: {
						required: false,
						type: Number,
						default: 18
				},
				showLinkToFavs: {
						required: false,
						type: Boolean,
						default: true
				}
		},
		computed: {
				inFavs(){
						return this.apartmentFavs.findIndex(id => id === this.aptId) > -1
				},
				color(){
						return this.settings.primary_color ?? '#fff'
				},
				...mapGetters(['apartmentFavs','settings','currentProjectId','apartmentOnly'])
		},
		methods: {
				toggleFavs(aptId){
						this.$store.dispatch('toggleFavs', aptId)
				},
				navigateToFavorites(){
						this.$router.push(`/projects/${this.currentProjectId}/favorites`)
				}
		}
}
</script>

<style scoped lang="scss">
span.fav-button-wrapper {
		position: relative;
		display: inline-block;

		a {
				position: absolute;
				background-color: rgb(0 0 0 / 60%);
				padding: 2px 5px;
				font-size: 13px;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				z-index: -1;
				opacity: 0;
				transition: all .5s ease;
				font-weight: bold;
				border-radius: 4px;
		}

		&:hover a,
		button:focus + a{
				opacity: 1;
				z-index: 1;
				right: 100%;
		}

}

</style>
