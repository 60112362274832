<template>
    <div class="page admin">
				<nav v-if="accessToken">
						<h4>{{$t('תפריט ניהול')}}</h4>
						<ul>
								<li>
										<router-link to="/fp-admin/projects">
												{{ $t('פרוייקטים') }}
										</router-link>
								</li>
								<li>
										<router-link to="/fp-admin/buildings">
												{{ $t('בניינים') }}
										</router-link>
								</li>
								<li>
										<router-link to="/fp-admin/apartments">
												{{ $t('דירות') }}
										</router-link>
								</li>
								<li>
										<router-link to="/fp-admin/logout">
												{{ $t('התנתקות') }}
										</router-link>
								</li>
						</ul>
				</nav>
        <router-view></router-view>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
    name: "AdminPage",
		computed: {
				...mapGetters(['accessToken'])
		}
}
</script>
<style scoped lang="scss">
.page.admin {
		background-color: rgba(0,0,0,.9);
		backdrop-filter: blur(20px);
		padding: 25px;
		display: flex;
		flex-flow: row nowrap;
		gap: 10px;

		nav {
				border-inline-end: solid 1px #fff;
				padding: 5px 10px;
				white-space: nowrap;
		}

		nav ul li a {
				padding: 7px 0px;
				display: block;
				border-bottom: solid 1px #fff;
		}
}
</style>
<style lang="scss">
@import "../admin";
</style>
