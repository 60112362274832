<template>
    <a class="tel-link" v-if="type === 'whatsapp'" v-bind="$attrs" :href="`${whatsappBaseLink}${tel}${whatsappMessage}`" target="_blank">
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.9808 4.23249C18.9112 3.15207 17.6372 2.29544 16.233 1.71255C14.8289 1.12967 13.3228 0.832198 11.8025 0.837495C5.43246 0.837495 0.240794 6.02916 0.240794 12.3992C0.240794 14.4408 0.777461 16.4242 1.78079 18.1742L0.147461 24.1708L6.27246 22.5608C7.96413 23.4825 9.8658 23.9725 11.8025 23.9725C18.1725 23.9725 23.3641 18.7808 23.3641 12.4108C23.3641 9.31916 22.1625 6.41416 19.9808 4.23249ZM11.8025 22.0125C10.0758 22.0125 8.38413 21.5458 6.90246 20.6708L6.55246 20.4608L2.91246 21.4175L3.88079 17.8708L3.64746 17.5092C2.68816 15.9773 2.17879 14.2066 2.17746 12.3992C2.17746 7.10249 6.49413 2.78583 11.7908 2.78583C14.3575 2.78583 16.7725 3.78916 18.5808 5.60916C19.4762 6.50045 20.1858 7.5606 20.6684 8.72816C21.1511 9.89571 21.3972 11.1475 21.3925 12.4108C21.4158 17.7075 17.0991 22.0125 11.8025 22.0125ZM17.0758 14.8258C16.7841 14.6858 15.3608 13.9858 15.1041 13.8808C14.8358 13.7875 14.6491 13.7408 14.4508 14.0208C14.2525 14.3125 13.7041 14.9658 13.5408 15.1525C13.3775 15.3508 13.2025 15.3742 12.9108 15.2225C12.6191 15.0825 11.6858 14.7675 10.5891 13.7875C9.72579 13.0175 9.15413 12.0725 8.97913 11.7808C8.81579 11.4892 8.95579 11.3375 9.10746 11.1858C9.23579 11.0575 9.39913 10.8475 9.53913 10.6842C9.67913 10.5208 9.73746 10.3925 9.8308 10.2058C9.92413 10.0075 9.87746 9.84416 9.80746 9.70416C9.73746 9.56416 9.15413 8.14083 8.92079 7.5575C8.68746 6.9975 8.44246 7.06749 8.26746 7.05583H7.70746C7.50913 7.05583 7.20579 7.12583 6.93746 7.4175C6.68079 7.70916 5.93413 8.40916 5.93413 9.8325C5.93413 11.2558 6.97246 12.6325 7.11246 12.8192C7.25246 13.0175 9.15413 15.9342 12.0475 17.1825C12.7358 17.4858 13.2725 17.6608 13.6925 17.7892C14.3808 18.0108 15.0108 17.9758 15.5125 17.9058C16.0725 17.8242 17.2275 17.2058 17.4608 16.5292C17.7058 15.8525 17.7058 15.2808 17.6241 15.1525C17.5425 15.0242 17.3675 14.9658 17.0758 14.8258Z" fill="white"/>
        </svg>
        <slot></slot>
    </a>
    <a class="tel-link" v-else v-bind="$attrs" :href="`tel:${tel}`">
        <span v-if="tel.length <= 5">{{tel}}</span>
        <svg v-else width="24" height="25" fill="#fff" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 473.806 473.806">
            <path d="M374.456,293.506c-9.7-10.1-21.4-15.5-33.8-15.5c-12.3,0-24.1,5.3-34.2,15.4l-31.6,31.5c-2.6-1.4-5.2-2.7-7.7-4
                c-3.6-1.8-7-3.5-9.9-5.3c-29.6-18.8-56.5-43.3-82.3-75c-12.5-15.8-20.9-29.1-27-42.6c8.2-7.5,15.8-15.3,23.2-22.8
                c2.8-2.8,5.6-5.7,8.4-8.5c21-21,21-48.2,0-69.2l-27.3-27.3c-3.1-3.1-6.3-6.3-9.3-9.5c-6-6.2-12.3-12.6-18.8-18.6
                c-9.7-9.6-21.3-14.7-33.5-14.7s-24,5.1-34,14.7c-0.1,0.1-0.1,0.1-0.2,0.2l-34,34.3c-12.8,12.8-20.1,28.4-21.7,46.5
                c-2.4,29.2,6.2,56.4,12.8,74.2c16.2,43.7,40.4,84.2,76.5,127.6c43.8,52.3,96.5,93.6,156.7,122.7c23,10.9,53.7,23.8,88,26
                c2.1,0.1,4.3,0.2,6.3,0.2c23.1,0,42.5-8.3,57.7-24.8c0.1-0.2,0.3-0.3,0.4-0.5c5.2-6.3,11.2-12,17.5-18.1c4.3-4.1,8.7-8.4,13-12.9
                c9.9-10.3,15.1-22.3,15.1-34.6c0-12.4-5.3-24.3-15.4-34.3L374.456,293.506z M410.256,398.806
                C410.156,398.806,410.156,398.906,410.256,398.806c-3.9,4.2-7.9,8-12.2,12.2c-6.5,6.2-13.1,12.7-19.3,20
                c-10.1,10.8-22,15.9-37.6,15.9c-1.5,0-3.1,0-4.6-0.1c-29.7-1.9-57.3-13.5-78-23.4c-56.6-27.4-106.3-66.3-147.6-115.6
                c-34.1-41.1-56.9-79.1-72-119.9c-9.3-24.9-12.7-44.3-11.2-62.6c1-11.7,5.5-21.4,13.8-29.7l34.1-34.1c4.9-4.6,10.1-7.1,15.2-7.1
                c6.3,0,11.4,3.8,14.6,7c0.1,0.1,0.2,0.2,0.3,0.3c6.1,5.7,11.9,11.6,18,17.9c3.1,3.2,6.3,6.4,9.5,9.7l27.3,27.3
                c10.6,10.6,10.6,20.4,0,31c-2.9,2.9-5.7,5.8-8.6,8.6c-8.4,8.6-16.4,16.6-25.1,24.4c-0.2,0.2-0.4,0.3-0.5,0.5
                c-8.6,8.6-7,17-5.2,22.7c0.1,0.3,0.2,0.6,0.3,0.9c7.1,17.2,17.1,33.4,32.3,52.7l0.1,0.1c27.6,34,56.7,60.5,88.8,80.8
                c4.1,2.6,8.3,4.7,12.3,6.7c3.6,1.8,7,3.5,9.9,5.3c0.4,0.2,0.8,0.5,1.2,0.7c3.4,1.7,6.6,2.5,9.9,2.5c8.3,0,13.5-5.2,15.2-6.9
                l34.2-34.2c3.4-3.4,8.8-7.5,15.1-7.5c6.2,0,11.3,3.9,14.4,7.3c0.1,0.1,0.1,0.1,0.2,0.2l55.1,55.1
                C420.456,377.706,420.456,388.206,410.256,398.806z"/>
            <path d="M256.056,112.706c26.2,4.4,50,16.8,69,35.8s31.3,42.8,35.8,69c1.1,6.6,6.8,11.2,13.3,11.2c0.8,0,1.5-0.1,2.3-0.2
                c7.4-1.2,12.3-8.2,11.1-15.6c-5.4-31.7-20.4-60.6-43.3-83.5s-51.8-37.9-83.5-43.3c-7.4-1.2-14.3,3.7-15.6,11
                S248.656,111.506,256.056,112.706z"/>
            <path d="M473.256,209.006c-8.9-52.2-33.5-99.7-71.3-137.5s-85.3-62.4-137.5-71.3c-7.3-1.3-14.2,3.7-15.5,11
                c-1.2,7.4,3.7,14.3,11.1,15.6c46.6,7.9,89.1,30,122.9,63.7c33.8,33.8,55.8,76.3,63.7,122.9c1.1,6.6,6.8,11.2,13.3,11.2
                c0.8,0,1.5-0.1,2.3-0.2C469.556,223.306,474.556,216.306,473.256,209.006z"/>
        </svg>
    </a>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "TelLink",
    props: {
        tel: {
            type: String
        },
        type: {
            type: String,
            default: 'tel'
        },
        message: {
            required: false,
            type: String
        },
    },
    computed: {
        whatsappBaseLink(){
            if(this.isMobile){
                return "whatsapp://send?phone="
            }
            return "http://api.whatsapp.com/send?phone="
        },
        whatsappMessage(){
            if(this.message){
                return `&text=${encodeURI(this.message)}`
            }
            return '';
        },
        ...mapGetters(['isMobile'])
    }
}
</script>
<style scoped lang="scss">
    a.tel-link {
        direction: ltr;
        color: #fff;
        text-decoration: none;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: .5rem;

        svg {
            flex-shrink: 0;
        }
    }
</style>
