<template>
		<div class="apartments-nav-arrows" v-if="nextApartment || previousApartment">
				<fp-icon-button
						symbol="angel-right"
						@click="goToPreviousApartmentPage"
						:disabled="!previousApartment"
						:title="$t('הדירה הקודמת')"
				></fp-icon-button>
				<div class="separator"><fp-icon symbol="dot"></fp-icon></div>
				<fp-icon-button
						symbol="angel-left"
						@click="goToNextApartmentPage"
						:disabled="!nextApartment"
						:title="$t('הדירה הבאה')"
				></fp-icon-button>
		</div>
    <div class="apartment-modal" v-bind="$attrs">
        <template v-if="apartment">
            <div class="modal-header">
								<close-btn v-if="!apartmentOnly" @click.stop="close"></close-btn>
                <div class="modal-title" tabindex="0" @click="goToApartmentPage">
										<apartment-title
												:apartment="apartment"
										></apartment-title>
										<apartment-availability :available="apartment.available"></apartment-availability>
                </div>
            </div>
            <div class="modal-content">
								<div
										class="apt-thumbnail" :class="{clickable: aptIsClickable}"
										v-if="showThumbnail && apartment.type_thumbnail"
										tabindex="1" role="button" @click="goToApartmentPage"
								>
									<img :src="apartment.type_thumbnail" :alt="apartment.name">
								</div>
								<div class="apt-buttons" v-if="apartment.buttons && Array.isArray(apartment.buttons)">
										<fp-button
												v-for="(button, btnInd) in apartment.buttons"
												:key="btnInd"
												class="active-icon"
												:class="{active: isCurrentContent(button)}"
												expand="block"
												variant="primary-outline"
												@click="contentChange(button)"
										>{{button.label}}</fp-button>
								</div>
                <div class="apt-features">
                    <hr v-if="!isMobile">
                    <apartment-features-list :apartment="apartment" location="page"></apartment-features-list>
                    <hr v-if="!isMobile && apartment.price">
                    <div class="apt-price flex flex-row flex-nowrap" v-if="apartment.price">
                        <span class="flex-grow-1">{{$t('מחיר')}}</span>
                        <span v-html="apartment.price"></span>
                    </div>
                </div>
								<template v-if="showButtons && (showApartmentShareButton || apartmentContactButton.show)">
										<div class="social-buttons">
												<fp-icon-button
														v-if="apartmentContactButton.show"
														@click="$store.commit('toggleContactModal')"
														class="btn btn-secondary-outline"
														symbol="phone-ring"
														:title="$t('דבר עם סוכן')"
												>
														<span v-if="apartmentContactButton.title && apartmentContactButton.translate">{{$t(apartmentContactButton.title)}}</span>
														<span v-else-if="apartmentContactButton.title">{{apartmentContactButton.title}}</span>
														<span v-else>{{$t('דבר עם סוכן')}}</span>
												</fp-icon-button>
												<fp-icon-button
														v-if="showApartmentShareButton"
														@click="shareApartment"
														class="btn btn-secondary-outline"
														symbol="share"
														:title="$t('שיתוף')"
												>{{$t('שיתוף')}}</fp-icon-button>
										</div>
								</template>
								<div v-if="showLink" class="link-button-wrapper">
										<fp-button @click="goToApartmentPage">{{$t('מעבר לדירה')}}</fp-button>
								</div>
								<related-apartments v-if="!apartmentOnly && apartment.related_products" :apartments="apartment.related_products"></related-apartments>
            </div>
        </template>
    </div>
</template>

<script>
import CloseBtn from "@/components/buttons/CloseBtn"
import {mapGetters, mapState} from "vuex"
import FpButton from "@/components/buttons/FpButton"
import FpIconButton from "@/components/buttons/FpIconButton"
import {delay} from "@/utils"
import ApartmentFeaturesList from "@/components/ApartmentFeaturesList"
import ApartmentTitle from "@/components/apartment/ApartmentTitle"
import RelatedApartments from "@/components/RelatedApartments"
import FpIcon from "@/components/FpIcon"
import ApartmentAvailability from "@/components/apartment/ApartmentAvailability.vue"

export default {
    name: "ApartmentModal",
    props: {
        apartment: {
            required: false,
            type: Object,
            default: null
        },
				nextApartment: {
						required: false,
						type: Object,
						default: null
				},
				previousApartment: {
						required: false,
						type: Object,
						default: null
				},
				showButtons: {
						type: Boolean,
						default: true
				},
				showLink: {
						type: Boolean,
						default: false
				},
				navigateOnButtonClick: {
						type: Boolean,
						default: false
				},
				showThumbnail: {
						type: Boolean,
						default: false
				}
    },
		emits: ['close', 'apartment-clicked', 'next-apartment-clicked', 'previous-apartment-clicked'],
    components: {
				ApartmentAvailability,
				FpIcon,
				RelatedApartments,
				ApartmentTitle,
        ApartmentFeaturesList,
        FpIconButton,
        FpButton,
        CloseBtn
    },
    computed: {
				aptIsClickable(){
						return this.apartmentPageEnabled && this.apartment.type_show_page
				},
				isApartmentPage(){
						return this.$route.name === 'apartment'
				},
        ...mapState(['currentProjectId', 'apartmentContent']),
        ...mapGetters([
						'isMobile',
						'isDesktop',
						'isTablet',
						'apartmentOnly',
						'apartmentPageEnabled',
						'showApartmentShareButton',
						'apartmentContactButton'
				])
    },
    watch: {
        apartment(apartment){
						if(!this.isMobile){
								if(apartment && apartment.buttons && Array.isArray(apartment.buttons) && apartment.buttons[0]){
										this.contentChange(apartment.buttons[0])
								}else if(this.apartmentContent){
										this.$store.commit('apartmentContent', null)
								}
						}
        }
    },
    data(){
        return {
            content: null
        }
    },
    methods: {
        close(){
						if(this.isApartmentPage){
								this.$router.push({name: 'apartments', params: {id: this.currentProjectId}})
						}
						this.$emit('close')
        },
        isCurrentContent(button){
            return JSON.stringify(this.apartmentContent) === JSON.stringify(button);

        },
        async contentChange(content){
            if(this.apartmentContent){
                this.$store.commit('apartmentContent', null)
                await delay(300)
            }
            this.$store.commit('apartmentContent', content)
						if(this.navigateOnButtonClick){
								await this.$router.push({name: 'apartment', params: {projectId: this.currentProjectId, apartmentId: this.apartment.id}})
						}
        },
        shareApartment(){
            this.$store.dispatch('shareDialogToggle', {show: true, apartment: this.apartment})
        },
				goToApartmentPage(){
						if(this.aptIsClickable){
								this.$emit('apartment-clicked', this.apartment)
						}
				},
				goToNextApartmentPage(){
						if(!this.isApartmentPage){
							this.$emit('next-apartment-clicked', this.nextApartment)
						}
				},
				goToPreviousApartmentPage(){
						if(!this.isApartmentPage){
								this.$emit('previous-apartment-clicked', this.previousApartment)
						}
				}
    }
}
</script>

<style scoped lang="scss">
.apartments-nav-arrows {
		width: 311px;
		margin-top: 10px;
		display: flex;
		justify-content: space-between;
		padding: .5rem .5rem;
		background: linear-gradient(90deg, rgba(0, 0, 0, 0.79) 0%, #000000 78.65%, #000000 100%);

		.separator svg {
				width: 10px;
		}
}
.apartment-modal {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.79) 0%, #000000 78.65%, #000000 100%);
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    padding: 0.9375rem;
    width: 311px;
    position: relative;

    .social-buttons {
        text-align: center;
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        flex-flow: row nowrap;
        gap: .8rem;
        align-items: center;

        button {
            color: #fff;
            border-radius: 5px;
						min-height: 0;
						font-size: 0.875rem;
						padding: 4px;
						
        }
    }

    .modal-header {
        padding-bottom: 0.9375rem;
				display: flex;
				flex-flow: row-reverse;
				justify-content: space-between;
				align-items: center;
				gap: 5px;

				.modal-title {
						display: flex;
						align-items: center;
						cursor: pointer;
				}
    }

    hr {
        border-color: #56595A;
        margin: 0;
    }

    .apt-price {
        padding: 0.9375rem 0.625rem;

        :deep(span.woocommerce-Price-currencySymbol) {
            margin: 0 0.3125rem;
        }
    }

		.link-button-wrapper {
				text-align: center;
				margin-top: 10px;
		}

		.apt-thumbnail {
				margin-bottom: 1.5rem;
		}

		.clickable {
				cursor: pointer;
		}

		:deep(.model-frame-wrapper) {
				margin-right: auto;
				margin-left: auto;
		}
}

.is-mobile {
    .apartment-modal {
        background-color: #000;

				.modal-header {
						flex-flow: row;
				}

        .modal-content {
            display: flex;
            flex-flow: column;

						.related-apartments {
								width: 100%;
						}

						.apt-buttons {
								display: grid;
								grid-template-columns: 1fr 1fr;
								gap: 15px;
								width: 100%;
								padding: 5px 0 10px;

								button {
										margin-bottom: 0;
								}
						}

						.apt-features {
								margin: 10px 0 5px;
								padding: 15px 0;
						}
        }

        .apt-price {
            border: solid 1px #B7B6B6;
            border-radius: 5px;
        }
    }
}

.fp-side-menu-wrapper + .fp-app-wrapper {

		.apartment-modal {
				display: flex;
				flex-flow: column;
				border: none;
				border-radius: 0;

				.modal-content {
						display: flex;
						flex-flow: column;
						justify-content: flex-start;
						flex-grow: 1;

						> .apt-features:first-child {
								margin-top: calc(10vh);
						}
				}

				.apt-buttons,
				.social-buttons {
						display: grid;
						grid-template-columns: 1fr 1fr;
						column-gap: 10px;
				}

				.social-buttons {
						padding: 1rem 1rem 0;
						margin-top: 0;

						button.btn.btn-secondary-outline {
								padding: 4px;
								min-height: 0;
								
						}
				}


				/*.apt-buttons {
						margin: auto 0;
						flex-grow: 1;
						display: flex;
						flex-flow: column;
						align-items: stretch;

						.fp-nf-btn.expand-block {
								display: block;
						}

						.fp-nf-btn {
								color: #fff;
								font-size: 1.125rem;
								text-align: start;
								position: relative;
						}

						.fp-nf-btn::before {
								content: " | ";
								color: var(--primary);
								visibility: hidden;
								position: absolute;
								left: 100%;
								top: 0;
						}

						.fp-nf-btn.active,
						.fp-nf-btn:hover {
								font-weight: 500;
						}

						.fp-nf-btn.active::before,
						.fp-nf-btn:hover::before{
								visibility: visible;
						}
				}*/
		}

}
</style>
