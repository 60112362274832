<template>
    <router-link v-if="to" custom :to="to" v-slot="{ navigate, isExactActive, isActive }">
        <button v-bind="$attrs" @click="navigate" type="button" class="fp-icon-button" :class="{'router-link': isActive, 'router-link-exact-active': isExactActive}">
						<lord-icon v-if="animated"
								:icon="animated"
								trigger="hover"
								colors="primary:#ffffff"
								:state="state"
								target=".fp-icon-button"
						></lord-icon>
            <svg-sprite v-else-if="symbol && symbolType === 'plain'" :symbol="symbol" :size="symbolSize" />
            <gradiant-icon v-else-if="symbol && symbolType === 'gradiant'" :symbol="symbol" :size="symbolSize" />
            <slot></slot>
        </button>
    </router-link>
    <button v-else v-bind="$attrs" class="fp-icon-button">
				<lord-icon
						v-if="animated"
						:icon="animated"
						trigger="hover"
						colors="primary:#ffffff"
						:state="state"
						target=".fp-icon-button"
						:style="{width: `${symbolSize}px`, height: `${symbolSize}px`}"
				></lord-icon>
        <svg-sprite v-else-if="symbol && symbolType === 'plain'" :symbol="symbol" :size="symbolSize" />
        <gradiant-icon v-else-if="symbol && symbolType === 'gradiant'" :symbol="symbol" :size="symbolSize" />
        <slot></slot>
    </button>
</template>

<script>
import GradiantIcon from "@/components/GradiantIcon";
export default {
    name: "FpIconButton",
    components: {GradiantIcon},
    props: {
        symbol: {
            type: String
        },
				animated: {
						type: String
				},
				state: {
						type: String,
						default: 'hover'
				},
        symbolSize: {
            default: '20'
        },
        symbolType: {
            type: String,
            default: 'plain'
        },
        to: {
            default: null
        }
    }
}
</script>

<style scoped lang="scss">
    button.fp-icon-button {
        color: #fff;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

				lord-icon {
						flex-shrink: 0;
				}

				&[disabled] {
						opacity: .5;
						cursor: not-allowed;
				}

        /*svg {
            fill: #fff;
            stroke: #fff;
        }

        &:focus svg,&:hover svg {
            fill: #00C3B8;
            stroke: #00C3B8;
        }*/
    }

		.ltr button.fp-icon-button{
				svg,img {
						transform: scaleX(-1);
				}
		}
</style>
