<template>
    <div class="page content-page">
        <transition appear>
            <div class="content-modal">
                <close-btn @click.stop="close"></close-btn>
                <div class="post-header">
                    <div class="post-title">
												הצהרת נגישות – אתר "OLYO״
										</div>
                </div>
                <div class="post-content">
										<p><strong><u>הצהרת הנגישות - פתיח</u></strong></p>
										<p><strong>וואי סטודיו הפקות בע"מ  ח.פ 516762424 ("החברה") </strong>שואפים לספק חווית שימוש מרבית באתר לכלל הציבור לרבות ציבור בעלי המוגבלויות, השקענו מאמצים רבים במטרה לאפשר, להקל ולייעל את השימוש באתר בדגש על צרכי ציבור זה. <strong><u> </u></strong></p>
										<p><strong><u>התוסף</u></strong></p>
										<ol>
												<li>לשם הנגשת האתר, הטמענו תוסף נגישות (להלן: "<strong>התוסף</strong>").</li>
												<li>באמצעות התוסף, ניתן להטמיע באתר פתרונות נגישות מתקדמים, מבלי לעשות שינויים בקוד האתר )התאמה לרגולציית ADA).</li>
										</ol>
										<p><strong><u>רמת הנגישות</u></strong></p>
										<ol start="3">
												<li>אתר זה עומד בדרישות תקנות שיוויון זכויות לאנשים עם מוגבלות (התאמות נגישות לשירות), התשע"ג 2013.</li>
												<li>התאמות הנגישות בוצעו עפ"י המלצות התקן הישראלי (ת"י 5568) לנגישות תכנים באינטרנט ברמת AA.</li>
												<li>תקן ישראלי זה הינו זהה למסמך הקווים המנחים של הארגון הבינלאומי העוסק בהנגשת אתרים W3C- מסמך WCAG 2.0 .</li>
										</ol>
										<p><strong><u>תיקונים והתאמות שבוצעו</u></strong></p>
										<ol start="6">
												<li>ההתאמות מותאמות לתצוגה במרבית הדפדפנים וכן לשימוש בטלפון סלולרי.</li>
												<li>בין היתר, באמצעות התוסף, המשתמש יוכל להשתמש בתוספים כדלקמן:</li>
										</ol>
										<ul>
												<li>הדגשת קישורים באתר;</li>
												<li>ניגודיות צבעים באתר;</li>
												<li>הגדלת הטקסט;</li>
												<li>ריווח הטקסט;</li>
												<li>סמן מוגדל;</li>
												<li>יישור הטקסט;</li>
												<li>גופן קריא;</li>
												<li>ועוד, כפי שמפורט בתוסף באתר.</li>
										</ul>
										<p><strong><u>החרגות</u></strong></p>
										<p>חרף מאמצנו לאפשר גלישה באתר נגיש עבור כל דפי האתר, יתכן ויתגלו דפים באתר שטרם הונגשו, או שטרם נמצא הפתרון הטכנולוגי המתאים. אנו ממשיכים במאמצים לשפר את נגישות האתר, ככל האפשר, וזאת מתוך אמונה ומחויבות מוסרית לאפשר שימוש באתר לכלל האוכלוסייה לרבות אנשים עם מוגבלויות.</p>
										<p><strong><u>פרטי אחראי נגישות</u></strong></p>
										<p>שם: <u>תומר אדרי</u>;</p>
										<p>אימייל: <a href="mailto:office@studioy.io">office@studioy.io</a></p>
										<p>טלפון: 050-4611161;</p>
										<p><strong><u>יצירת קשר</u></strong></p>
										<ol start="8">
												<li>נשמח לתת מענה לכל טענה בנוגע לנושאי הנגישות.</li>
												<li>על מנת שנוכל להתייחס לטענה נודה על פנייה רחבה ככל שניתן הכוללת את תיאור הבעיה, את הדפדפן בו השתמשתם, את העמוד שבו ניסיתם לבצע פעולה שלא צלחה וכיוצ"ב.</li>
												<li>את הפנייה ניתן לעשות באמצעים הבאים:</li>
										</ol>
										<p><u>כתובת</u>: דויד המלך 32, קרית גת;</p>
										<p><u>טלפון</u>: 050-4611161;</p>
										<p><u>דוא"ל</u>:  office@studioy.io</p>
										<p>עדכון אחרון: יולי 2023</p>
								</div>
            </div>
        </transition>
    </div>
</template>

<script>
import CloseBtn from "@/components/buttons/CloseBtn";

export default {
    name: "AccessibilityStatementPage",
    components: {CloseBtn},
    computed:{
				siteUrl(){
					return location.host
        }
    },
		methods: {
				close(){
						this.$router.push({name: 'home'})
				}
		}
}
</script>

<style scoped lang="scss">

.content-page {
    display: flex;
    justify-content: center;
    padding: 0 2.6875rem;

    &.v-enter-active,
    &.v-leave-active {
        transition: transform 1s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: translateY(100vh);
    }
}

.content-modal {
		position: relative;
		height: 100vh;
		max-height: calc(100vh - 40px);
		width: 100%;
		max-width: 100%;
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 87.46%, #000000 93.93%);
		box-sizing: border-box;
		display: flex;
		align-items: stretch;
		flex-flow: column nowrap;

    .close-btn {
        position: absolute;
        top: 1.875rem;
        left: 1.875rem;
    }

    .post-header {
        margin-top: 3.75rem;
        margin-bottom: 1.8rem;
        margin-right: 4.0625rem;
        margin-left: 4.0625rem;
        display: flex;
        align-items: center;
        line-height: 1;

        .post-title {
            font-weight: 700;
            font-size: 1.875rem;
        }

        .post-icon + .post-title {
            margin: 0 0.625rem;
        }
    }

    .post-content {
        margin: 0 4.0625rem 6.25rem;
        padding: 0 0.625rem;
        overflow: auto;
				ul,
				ol{
						padding-right: 15px;
						list-style: auto;
						margin-bottom: 10px;
						line-height: 1.2;
						li {
								margin-bottom: 5px;
						}
				}

				p {
						margin-bottom: 10px;
						line-height: 1.2;
				}
        /* scrollbar design for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            width: 8px;
            background-color: #aaa;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--primary);
        }

        /* scrollbar design for Edge and Firefox */
        & {
            scrollbar-width: thin;
            scrollbar-color: var(--primary) #aaa;
        }
    }
}

.is-mobile,
.is-tablet {
    .content-modal {
        max-height: calc(100vh - 190px);

        .post-content {
            margin: 0;
        }
    }
}

.is-mobile {
    .content-page {
        padding: 0;

        .post-content {
            overflow: visible;
        }
    }
    .content-modal {
        height: auto;
        width: 100vw;
        max-width: none;
        max-height: none;
        border-radius: 0;
        border: none;
        background-color: rgba(0, 0, 0, 0.86);
        background-image: none;
        padding-bottom: 6rem;
				padding-right: 15px;
				padding-left: 15px;
    }
}

</style>
