<template>
		<lord-icon
				v-if="animated"
				:icon="animated"
				trigger="hover"
				class="current-color"
				:state="state"
				:target="target"
				:style="{width: `${size}px`, height: `${size}px`}"
		></lord-icon>
		<svg-sprite v-else v-bind="$attrs" :size="iconSize" />
</template>

<script>
export default {
    name: "FpIcon",
		props: {
        size: {
            default: '23'
        },
				animated: {
						default: null
				},
				state: {
						default: 'hover'
				},
				target: {
						default: null
				}
    },
		data(){
				return {
						animationData: null
				}
		},
    computed: {
        iconSize(){
            return `${this.size}`
        }
    }
}
</script>
<style scoped lang="scss">

</style>
