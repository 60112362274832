<template>
    <div class="form-group" :class="{inline: inline, 'input-underline': isSideMenu}">
        <label
						v-if="$slots.label"
						:for="id"
				><slot name="label"></slot></label>
        <label
						v-else-if="label"
						:for="id"
				>{{label}}</label>
        <textarea v-if="type === 'textarea'"
                  v-bind="$attrs"
									ref="input"
                  :id="id"
                  :value="modelValue"
                  @input="change"
        ></textarea>
        <input
						v-else
						v-bind="$attrs"
						ref="input"
						:id="id"
						:type="type"
						:value="modelValue"
						@input="change"
        >
    </div>
</template>

<script>
import { nanoid } from 'nanoid'
import {mapGetters} from "vuex"
import slugify from "slugify";

export default {
    name: "FpInput",
    props: {
        modelValue: {
						default: null
				},
        type:{
            type: String,
            default: 'text'
        },
				label:{
            type: String,
            default: null
        },
        id: {
            type: String,
            default(){
								return nanoid()
						}
        },
        inline: {
            type: Boolean,
            default: true
        },
				enforce: {
						type: String,
						default: null
				}
    },
		data(){
				return {
						value: null
				}
		},
		computed: {
				slug() {
						return slugify(this.value, { lower: true, remove: /[*+~.()'"!:@]/g })
				},
				...mapGetters(['isSideMenu'])
		},
		watch: {
				value(newValue, oldValue){
						if(newValue !== oldValue){
								if(this.enforce === 'slugify'){
										this.$emit('update:modelValue', this.slug)
								}else{
										this.$emit('update:modelValue', newValue)
								}
						}
				}
		},
    methods: {
        change(e){
            if(e instanceof InputEvent){
								this.value = e.target.value
						}
				},
				globalClick(){
						if(this.$refs.input && this.$refs.input.value && !this.value){
								this.value = this.$refs.input.value
						}
				}
    },
		mounted() {
				document.addEventListener('click', this.globalClick);
		},
		unmounted() {
				document.removeEventListener('click', this.globalClick);
		}
}
</script>

<style scoped lang="scss">
    .form-group {

        &:not(.inline){
            margin-bottom: 0.9375rem;
        }

        &.inline {
            display: inline-block;
        }

        label {
            display: block;
        }

        input[type="text"],
        input[type="tel"],
        input[type="email"],
        input[type="number"],
        input[type="date"],
        input[type="password"],
        input[type="url"],
        textarea {
            background-color: transparent;
            border-radius: 4px;
            height: 2.125rem;
            min-width: 210px;
            max-width: 100%;
            border: solid 1px #fff;
            color: #fff;
            padding: 5px 15px;
            box-sizing: border-box;
            font-size: 1.125rem;
            line-height: 1;

            &::placeholder {
                color: #fff;
            }
        }

        textarea {
            border-radius: 15px;
            resize: vertical;
            height: 3.125rem;
        }

				&.input-underline {
						position: relative;

						input[type="text"],
						input[type="tel"],
						input[type="email"],
						input[type="number"],
						input[type="date"],
						input[type="password"],
						input[type="url"],
						textarea {
								border: 0 none transparent;
								outline: transparent none 0;

								&:focus-visible,
								&:focus-within,
								&:focus {
										border: 0 none transparent;
										outline: transparent none 0;
								}
						}

						&::after {
							position: absolute;
							content: "";
							display: block;
							background-image: linear-gradient(270deg,var(--primary) 0%,transparent 98%);
							left: 0;
							right: 0;
							height: 2px;
						}
				}
    }
</style>
