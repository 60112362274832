<template>
    <div class="page project fade-in">
        <div class="centered-content">
            <transition appear>
                <project-modal v-if="projectModal" @button-clicked="projectButtonClicked" :current-button="currentButton" :project="project"></project-modal>
            </transition>
            <transition appear>
                <content-modal
										v-if="currentButton" @close="closeProjectButtonContent"
										:class="{'full': currentButtonType === 'gallery', 'tabs': currentButton.tabs && currentButton.tabs.length > 1}"
										:style="{paddingBottom: `${mobileButtonsHeight}px`}"
								>
                    <template #header v-if="currentButton.label && currentButton.tabs_layout === 'top_tabs'">
                        <span v-if="currentButtonType !== 'gallery'">{{currentButton.label}}</span>
												<nav v-else-if="currentButton.tabs && currentButton.tabs.length > 1" class="header-tabs">
														<ul>
																<li v-for="(tab, tabIndex) in currentButton.tabs" :key="tabIndex">
																		<button type="button" :class="{active: currentContentTabIndex === tabIndex}" @click="changeContentTab(tabIndex)">{{tab.label}}</button>
																</li>
														</ul>
												</nav>
                    </template>
                    <building-specs v-if="currentButtonType === 'specifications'" :content="currentButton"></building-specs>
                    <div class="gallery-slides" v-else-if="currentButtonType === 'gallery'">
                        <div v-if="currentContentTab">
														<template v-if="currentContentTab.acf_fc_layout === 'gallery'">
																<masonry-gallery
																		v-if="currentContentTab.type === 'masonry'"
																		:images="currentContentTab.gallery"
																		:items-per-row="isMobile ? currentContentTab.mobile_items_per_row : currentContentTab.items_per_row"
																></masonry-gallery>
																<slider-gallery v-else :slides="currentContentTab.gallery">
																		<template v-slot="{slide}">
																				<div
																						v-if="currentContentTabClickAction === 'edit' && !isMobile"
																						:class="`img-${slide.image_layout} image-edit`"
																						@click="$store.dispatch('openImageEditor', slide.url)"
																						tabindex="1"
																				>
																						<img :src="slide.url" :alt="slide.alt">
																						<click-here></click-here>
																				</div>
																				<div v-else-if="slide.type === 'image'" class="ps-gal" :class="[`type-${slide.type}`, `img-${slide.image_layout}`]">
																						<a
																								:href="slide.url"
																								:data-pswp-width="slide.width"
																								:data-pswp-height="slide.height"
																								target="_blank"
																								rel="noreferrer"
																						>
																								<img :src="slide.thumbnail" :alt="slide.alt">
																						</a>
																				</div>
																				<div v-else :class="[`type-${slide.type}`, `img-${slide.image_layout}`]">
																						<video :src="slide.url" controls></video>
																				</div>
																		</template>
																</slider-gallery>
														</template>
                            <div class="iframe" v-else-if="currentContentTab.acf_fc_layout === 'iframe'">
                                <iframe v-if="currentContentTab.src" :src="currentContentTab.src"></iframe>
                            </div>
														<masonry-gallery
																v-else-if="currentContentTab.acf_fc_layout === 'masonry'"
																:images="currentContentTab.gallery"
																:items-per-row="isMobile ? currentContentTab.mobile_items_per_row : currentContentTab.items_per_row"
														></masonry-gallery>
                        </div>
                    </div>
										<div class="flipbook-wrapper" v-else-if="currentButtonType === 'flipbook'">
												<fp-flip-book :pages="currentButton.images" :sizes="currentButton.images_sizes" :fallback-sizes="{width: currentButton.width,height: currentButton.height}"></fp-flip-book>
										</div>
										<template #footer>
												<nav
														v-if="currentButton.tabs_layout === 'bottom_buttons' && currentButton.tabs && currentButton.tabs.length > 1"
														class="footer-tabs"
												>
														<fp-button
																v-for="(tab, tabIndex) in currentButton.tabs"
																:key="tabIndex"
																:class="{active: currentContentTabIndex === tabIndex}"
																@click="changeContentTab(tabIndex)"
														>{{tab.label}}</fp-button>
												</nav>
										</template>
                </content-modal>
            </transition>
        </div>
    </div>
</template>

<script>
import ProjectModal from "@/components/ProjectModal"
import SliderGallery from "@/components/SliderGallery"
import ContentModal from "@/components/ContentModal"
import {mapGetters} from "vuex"
import {delay} from "@/utils"
import BuildingSpecs from "@/components/BuildingSpecs"
import FpFlipBook from "@/components/FpFlipBook"
import PhotoSwipeLightbox from "photoswipe/lightbox"
import 'photoswipe/style.css'
import MasonryGallery from "@/components/MasonryGallery"
import FpButton from "@/components/buttons/FpButton"
import ClickHere from "@/components/ClickHere.vue";

export default {
    name: "ProjectPage",
    components: {
				ClickHere,
				FpButton, MasonryGallery, FpFlipBook, BuildingSpecs, ContentModal, SliderGallery, ProjectModal},
    data(){
        return {
            currentButton: null,
						currentContentTabIndex: 0,
						lightbox: null
        }
    },
    computed: {
        currentButtonType(){
            return this.currentButton && this.currentButton.acf_fc_layout ? this.currentButton.acf_fc_layout : null
        },
        project(){
            return this.$store.getters.currentProject
        },
				currentContentTab(){
						if(this.currentButton.tabs && this.currentButton.tabs[this.currentContentTabIndex] && this.currentButton.tabs[this.currentContentTabIndex].content && this.currentButton.tabs[this.currentContentTabIndex].content[0])
							return this.currentButton.tabs[this.currentContentTabIndex].content[0]
						return null
				},
				currentContentTabClickAction(){
						if(this.currentContentTab?.click_action){
							return this.currentContentTab.click_action
						}
						return null
				},
        ...mapGetters(['projectModal','mobileButtonsHeight','hasWhatsappButton','showFloatingContactButton','isMobile', 'bottomBarHeight'])
    },
    watch:{
        projectModal(newVal){
            if(!newVal){
                this.closeProjectButtonContent()
            }
        }
    },
    methods: {
				changeContentTab(tabIndex){
						this.currentContentTabIndex = tabIndex
						this.destroyPs()
						this.$nextTick().then(() => {
								this.initPs()
						})
				},
        loadProject(){
            /*const id = to.params.projectId ? parseInt(`${to.params.projectId}`, 10) : null
            this.$store.commit('selectProject', id)*/
						this.$store.commit('toggleProjectModal', true)
        },
        async projectButtonClicked(button){

						this.currentContentTabIndex = 0

            if(this.currentButton){
                this.currentButton = null
                await delay(500)
            }

            this.currentButton = button

						this.destroyPs()
						this.$nextTick().then(() => {
								this.initPs()
						})
        },
        closeProjectButtonContent(){
            this.currentButton = null
        },
				initPs(){
						if(this.currentContentTabClickAction !== 'edit' || this.isMobile){
								if (!this.lightbox) {
										this.lightbox = new PhotoSwipeLightbox({
												gallery: '.gallery-slides',
												children: '.ps-gal a',
												pswpModule: () => import('photoswipe'),
										});
										this.lightbox.init();
								}
						}
				},
				destroyPs(){
						if (this.lightbox) {
								this.lightbox.destroy();
								this.lightbox = null;
						}
				}
		},
    beforeRouteEnter(to, from, next){
        next(vm => {
            vm.loadProject()
        })
    },
    beforeRouteUpdate(){
        this.loadProject()
    }
}
</script>
<style scoped lang="scss">
.page.project {
    display: flex;
    align-items: flex-start;
    padding: 0 2.6875rem;

    .centered-content {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        flex-grow: 1;
        gap: 15px;
    }
}

.project-modal {
    width: 311px;
    flex-shrink: 0;
    flex-grow: 0;
    max-width: 100vw;

    &.v-enter-active,
    &.v-leave-active {
        transition: transform .5s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: translateX(600px);
    }
}

.content-modal {
    &.v-enter-active,
    &.v-leave-active {
        transition: transform .5s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: translateX(-100vw);
    }

		:deep(.content-modal-header) {
				display: flex;
				width: 100%;
				&.tabs {
						background-color: #000;
						color: #fff;
				}

				nav.header-tabs {
						flex-grow: 1;

						ul {
								display: flex;
						}

						ul button {
								color: #fff;
								font-size: 18px;
								margin: 0 6px;

								&.active {
										color: var(--primary-light);
								}
						}
				}
		}

		:deep(nav.footer-tabs) {
				display: flex;
				gap: 15px;
				margin-bottom: 15px;
				z-index: 10;
				overflow-x: auto;
				margin-right: 10px;
				margin-left: 10px;

				.btn {
						background-color: #000;
						&:first-child {
								margin-right: auto;
						}

						&:last-child {
								margin-left: auto;
						}
				}
		}
}

.project-current-button {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.86) 0%, #000000 100%);
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    flex-grow: 1;
    min-height: 70vh;
    max-width: calc(100vw - 470px - 15px - (2.6875rem * 2));

    &.v-enter-active,
    &.v-leave-active {
        transition: transform 1s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: translateX(-100vw);
    }

    iframe {
        width: 100%;
        height: 100%;
        min-height: calc(70vh - (1.875rem + 4.375rem));
    }

    .project-current-button-header {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        padding-right: 1.875rem;
        padding-left: 1.875rem;
        border-bottom: solid 1px #fff;
        max-width: 100%;
        min-height: 50px;
        flex-grow: 1;
    }

    .project-current-button-content {
        padding: 1rem 1.875rem 1.875rem;
    }

    &.full {
        .project-current-button-header {
            display: none;
        }
        .project-current-button-content {
            padding: 0;
        }
    }

    .project-current-button-header-inner {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        max-width: 100%;

        .project-current-button-title {
            flex-grow: 0;
        }

        ul.iframes-tabs {
            display: flex;
            flex-flow: row nowrap;

            li {
                padding: 1rem 3rem;
                white-space: nowrap;
            }
        }

        flex-grow: 1;
    }

    button.close-btn {
        flex-grow: 0;
        margin-top: 3px;
    }
}

.is-mobile {
    .page.project {
        padding: 0;
        flex-flow: column;

        .centered-content {
            flex-flow: column;
            gap: 0;
        }
    }

    .content-modal :deep(.content-modal-header){
        white-space: nowrap;
        max-width: 100vw;
        overflow-x: auto;
    }

    .project-current-button.full {
        .project-current-button-header {
            display: flex;
            border-bottom: none;
        }
    }

    .project-current-button,
    .project-modal {
        width: 100vw;
        max-width: 100vw;
        border-radius: 0;
        border: none;
    }

		.project-modal{
				padding-bottom: 100px;
		}
}

.fp-side-menu-wrapper + .fp-app-wrapper {
		.page.project {
				padding: 0;
				align-items: stretch;

				.centered-content {
						gap: 0;
						height: 100vh;
				}

				:deep(.content-modal){
						border: none;
						border-radius: 0;
						max-width: none;
				}
		}
}

.ltr {
		.project-modal {

				&.v-enter-from,
				&.v-leave-to {
						transform: translateX(-600px);
				}
		}

		.content-modal {
				&.v-enter-from,
				&.v-leave-to {
						transform: translateX(100vw);
				}

				:deep(nav.footer-tabs) {
						.btn {
								background-color: #000;
								&:first-child {
										margin-right: 10px;
										margin-left: auto;
								}

								&:last-child {
										margin-left: 10px;
										margin-right: auto;
								}
						}
				}
		}
}
</style>
