<template>
		<transition appear>
				<div v-if="hasCompare" class="apartments-compere">
						<fp-button type="button" @click="doCompression" variant="primary-outline">{{$t('השוואת דירות')}}</fp-button>
						<div class="chips">
								<div class="chip-wrap" v-for="(aptComp, aptCompId) in apartmentsCompare" :key="aptCompId">
										<div class="chip" v-if="aptComp">
												<fp-icon-button @click="apartmentsCompare[aptCompId] = false" symbol="times-circle-fill"></fp-icon-button>
												<template v-if="products && products[aptCompId]">
														<div class="chip-title">
																<span>{{$t('דגם')}} {{products[aptCompId].type}}</span>
														</div>
														<div class="chip-thumbnail" v-if="products[aptCompId].type_thumbnail">
																<img :src="products[aptCompId].type_thumbnail" :alt="products[aptCompId].name">
														</div>
												</template>
										</div>
								</div>
						</div>
				</div>
		</transition>
</template>

<script>
import {mapGetters} from "vuex";
import FpIconButton from "@/components/buttons/FpIconButton";
import FpButton from "@/components/buttons/FpButton";

export default {
		name: "ApartmentsCompere",
		components: {FpButton, FpIconButton},
		computed: {
				compare_enabled(){
						if(this.filterOptions && this.filterOptions.compare_enabled){
								return this.$store.getters.settings.apartments_filter.options.compare_enabled
						}
						return null
				},
				hasCompare(){
						if(this.compare_enabled){
								if(this.apartmentsCompare){
										const keys = Object.keys(this.apartmentsCompare).filter(a => this.apartmentsCompare[a])
										return keys && keys.length > 1
								}
						}
						return null
				},
				...mapGetters(['filterOptions','apartmentsCompare','products', 'currentProjectId'])
		},
		methods: {
				async doCompression(){
						if(this.compare_enabled){
								await this.$router.push({name: 'apartments-compare', params: {projectId: this.currentProjectId}})
						}
				}
		}
}
</script>

<style scoped lang="scss">
.apartments-compere {
		background: #000000;
		border: 0.5px solid #FFFFFF;
		box-sizing: border-box;
		position: absolute;
		border-radius: 0 0 3px 3px;
		bottom: 100%;
		display: flex;
		flex-flow: row-reverse nowrap;
		left: -2px;
		padding: 21px 18px 21px 10px;
		margin-bottom: 4px;
		gap: 18px;
		align-items: center;

		&.v-enter-active,
		&.v-leave-active {
				transition: transform .5s ease;
		}

		&.v-enter-from,
		&.v-leave-to {
				transform: translateX(-800px);
		}

		.chips {
				display: flex;
				flex-flow: row wrap;
				gap: 8px;

				.chip {
						display: flex;
						flex-flow: row-reverse nowrap;
						align-items: center;
						gap: 18px;
						border: solid 1px #464646;
						background-color: #171818;
						border-radius: 3px;

						.chip-thumbnail {
								width: 62px;
								height: 43px;
								border: 1px solid #FFFFFF;
								border-radius: 0px 3px 3px 0px;
								margin: -1px;
								overflow: hidden;
						}

						.chip-thumbnail img {
								height: 100%;
								width: 100%;
								object-fit: cover;
						}
				}
		}
}

.is-mobile {
		.apartments-compere {
				position: relative;
				left: auto;
				bottom: auto;
				flex-flow: column-reverse;
				align-items: flex-start;
				margin-top: 40px;
				padding-right: 0;
				padding-left: 0;
				border-right: none;
				border-left: none;
				border-bottom: none;
				border-top: solid 1px #7F7F7F;
				border-radius: 0;

				.chips {
						flex-wrap: nowrap;
						overflow-y: auto;
						max-width: calc(100vw - 12px);
						padding-bottom: 10px;
						padding-right: 20px;
						padding-left: 20px;
						box-sizing: border-box;

						.chip-title {
								white-space: nowrap;
						}
				}

				> :deep(button){
						margin-right: 20px;
				}
		}
}

.fp-side-menu-wrapper + .fp-app-wrapper {
		.apartments-compere {
				background-color: transparent;
				background-image: linear-gradient(to left, rgb(0 0 0 / 80%) 50%,rgb(0 0 0 / 90%) 100%);
				backdrop-filter: blur(5px);
				border: none;
		}
}
</style>
