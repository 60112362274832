<template>
    <div class="specs-wrapper">
        <h2 class="title">{{content.title}}</h2>
        <fp-tabs v-model="tab" :tabs="tabs"></fp-tabs>
        <template v-for="(tabContent, tabContentI) in content.tabs" :key="tabContentI">
            <div class="tab-content" v-if="tab === (tabContentI+'')">
                <div class="specifications" v-if="tabContent.specifications">
                    <div class="specification" v-for="(spec, specI) in tabContent.specifications" :key="specI">
												<fp-icon
														v-if="spec.animated_icon"
														:animated="spec.animated_icon"
														:state="spec.animated_icon_state"
														:style="{color: (spec.animated_icon_color || '#fff')}"
														target=".specification"
												></fp-icon>
                        <img v-else-if="spec.icon" :src="spec.icon" :alt="spec.title">
                        <div class="spec-title">
                            {{spec.title}}
                        </div>
                        <div class="spec-excerpt" v-html="spec.excerpt"></div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import FpTabs from "@/components/FpTabs";
import FpIcon from "@/components/FpIcon.vue";
export default {
    name: "BuildingSpecs",
    components: {FpIcon, FpTabs},
    props: {
        content: {
            required: true
        }
    },
    watch: {
        content(newVal){
            this.loadTabs(newVal)
        }
    },
    data(){
        return {
            tabs: {},
            tab: null
        }
    },
    methods: {
        loadTabs(content){
            if(content){
                if(content.acf_fc_layout && content.acf_fc_layout === 'specifications'){
                    if(content.tabs){
                        content.tabs.forEach((t, ti) => {
                            if(this.tab === null){
                                this.tab = `${ti}`
                            }
                            this.tabs[`${ti}`] = t.label
                        })
                    }
                }
            }
        }
    },
    mounted() {
        if(this.content){
            this.loadTabs(this.content)
        }
    }
}
</script>

<style scoped lang="scss">
.specs-wrapper {
    height: 73vh;

    .title {
        font-size: 1.875rem;
        font-weight: 700;
        text-align: center;
        margin: 1.875rem 0 3.125rem;
    }

    .specifications {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin: 15px auto;
				width: 100%;
				max-width: 995px;
    }

    .specification {
        flex-basis: 315px;
        background-color: rgba(179, 179, 179, 0.2);
        border: solid 1px #636560;
        text-align: center;
        padding: 25px 16px 20px;
        margin: 7px;

        .spec-title {
            font-size: 1.125rem;
            margin: 10px 0;
        }

        .spec-excerpt {
            white-space: break-spaces;
        }
    }
}

.is-mobile {
    .specs-wrapper {
        height: auto;
    }
}
</style>
