<template>
		<div v-if="apartmentStatusShow" class="apt-availability">
				<template v-if="isBoolean">
						<span v-if="available">{{$t('פנוי')}} <fp-icon class="dot" symbol="green-dot"></fp-icon></span>
						<span v-else>{{$t('לא פנוי')}} <fp-icon class="dot" symbol="red-dot"></fp-icon></span>
				</template>
				<template v-else-if="isEmpty">
						<span>{{$t('פנוי')}} <fp-icon class="dot" symbol="green-dot"></fp-icon></span>
				</template>
				<template v-else>
						<span>
								{{available}}
								<svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
										<ellipse cx="3.00384" cy="2.53791" rx="2.77239" ry="2.53794" :fill="color"/>
								</svg>
						</span>
				</template>
		</div>
</template>

<script>
import {mapGetters} from "vuex";
import FpIcon from "@/components/FpIcon";

export default {
		name: "ApartmentAvailability",
		components: {FpIcon},
		props: {
				available: {
						required: false,
						default: 'פנוי'
				}
		},
		computed: {
				color(){
						if(this.apartmentDataView && this.apartmentDataView.apartment_statuses){
								const status = this.apartmentDataView.apartment_statuses.find(s => s.status_name === this.available)
								if(status && status.status_color){
										return status.status_color;
								}
						}
						return '#3DF91E'
				},
				isBoolean(){
						return typeof(this.available) === "boolean" || this.available === 1 || this.available === 0 || this.available === '1' || this.available === "0"
				},
				isEmpty(){
						return this.available === ''
				},
				apartmentStatusShow(){
						if(this.filterOptions && typeof(this.filterOptions.apartment_status_show) !== 'undefined'){
								return this.filterOptions.apartment_status_show
						}
						return true
				},
				...mapGetters(['filterOptions', 'apartmentDataView'])
		}
}
</script>

<style scoped lang="scss">
.apt-availability {
		position: relative;
		font-size: 0.812rem;
		background: rgb(0 0 0 / 80%);
		border: 0.3px solid #7e7e7e;
		box-sizing: border-box;
		border-radius: 3px 0 0 3px;
		padding: 3px 15px 4px 20px;
		display: inline-block;
		min-width: 81px;

		svg {
				width: 6px;
				position: absolute;
				height: 6px;
				top: 4px;
				left: 10px;
		}
}
</style>
