<template>
    <div class="page main-page" :class="[fadeIn ? 'fade-in':'']">
        <!--<div class="logo">
            <main-logo></main-logo>
        </div>-->
				<weather-widget></weather-widget>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import WeatherWidget from "@/components/WeatherWidget";

export default {
    name: "MainPage",
		components: {WeatherWidget},
		data(){
        return {
            fadeddIn: false
        }
    },
    computed: {
        fadeIn(){
            return this.fadeddIn
        },
        ...mapState(['mainInit','currentProjectId']),
        ...mapGetters(['isDesktop'])
    },
    watch: {
        currentProjectId(newValue){
            if(newValue && this.isDesktop){
                this.$store.commit('mainInit')
                //this.$router.push({name: 'projects', params: {projectId: this.$store.getters.currentProjectId}})
            }
        }
    },
    mounted() {
        if(this.mainInit){
            this.fadeddIn = true
        }else{
            if(!this.fadeddIn){
                this.fadeddIn = true
            }
        }
    }
}
</script>

<style scoped lang="scss">

</style>
