<template>
  <div class="flipbook">
			<div v-for="(image, imageIndex) in pages" :key="imageIndex" class="flipbook-img">
					<a
						:href="image"
						target="_blank"
						:data-pswp-width="imageWidth(imageIndex)"
						:data-pswp-height="imageHeight(imageIndex)"
					>
							<img
									:src="image"
									alt="image">
					</a>
					<illustration-only-disclaimer></illustration-only-disclaimer>
			</div>
	</div>
</template>

<script>

import IllustrationOnlyDisclaimer from "@/components/IllustrationOnlyDisclaimer";
import PhotoSwipeLightbox from "photoswipe/lightbox";
export default {
		name: "FpFlipBook",
		components: {IllustrationOnlyDisclaimer},
		props: {
				pages: {
						required: false,
						default: () => {
								return []
						}
				},
				sizes: {
						required: false,
						default: () => {
								return []
						}
				},
				fallbackSizes: {
						required: false,
						default: {width: 1920, height: 1080}
				}
		},
		data() {
				return {
						lightbox: null
				}
		},
		methods: {
				imageWidth(index){
						if(this.sizes && this.sizes[index] && this.sizes[index].width){
							return this.sizes[index].width
						}
						return this.fallbackSizes.width
				},
				imageHeight(index){
						if(this.sizes && this.sizes[index] && this.sizes[index].height){
								return this.sizes[index].height
						}
						return this.fallbackSizes.height
				},
				initPs(){
						if(this.currentContentTabClickAction !== 'edit'){
								if (!this.lightbox) {
										this.lightbox = new PhotoSwipeLightbox({
												gallery: '.flipbook-img',
												children: 'a',
												initialZoomLevel: 'fill',
												pswpModule: () => import('photoswipe'),
										});
										this.lightbox.init();
								}
						}
				},
				destroyPs(){
						if (this.lightbox) {
								this.lightbox.destroy();
								this.lightbox = null;
						}
				}
		},
		mounted() {
				this.$nextTick().then(() => {
						this.initPs()
				})
		}
}
</script>

<style scoped lang="scss">
  .flipbook {
    width: 100%;
    height: calc(100vh - 250px);
		text-align: center;
			.flipbook-img {
					position: relative;
			}
  }

	.fp-side-menu-wrapper + .fp-app-wrapper {
			.flipbook {
					height: calc(100vh - 100px);
			}
	}
</style>
