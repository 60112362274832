<template>
	<transition appear>
			<div v-if="accessibilityModal" class="accessibility-modal">
					<div class="am-controls">
							<label>
									<input type="checkbox" v-model="actions.mono_color" @change="applyActions">
									{{ $t('צבעוניות חד גוונית') }}
							</label>
					</div>
					<div class="am-controls">
							<label>
									<input type="checkbox" v-model="actions.invert_color" @change="applyActions">
									{{ $t('צבעים הפוכים') }}
							</label>
					</div>
					<div class="am-controls">
							<label>
									<input type="checkbox" v-model="actions.brighten_colors" @change="applyActions">
									{{ $t('ניגודיות בהירה') }}
							</label>
					</div>
					<div class="am-controls">
							<label>
									<input type="checkbox" v-model="actions.dark_colors" @change="applyActions">
									{{ $t('ניגודיות כהה') }}
							</label>
					</div>
					<div class="am-controls">
							<label>
									<input type="checkbox" v-model="actions.block_blinks" @change="applyActions">
									{{ $t('חסום הבהובים') }}
							</label>
					</div>
					<div class="am-controls">
							<label>
									<input type="checkbox" v-model="actions.links_underline" @change="applyActions">
									{{ $t('קו תחתון מתחת לקישורים') }}
							</label>
					</div>
					<div class="am-controls">
							<label>
									<input type="checkbox" v-model="actions.disable_font_styles" @change="applyActions">
									{{ $t('הפוך טקסט לנגיש') }}
							</label>
					</div>
					<div class="am-controls">
							<label>{{ $t('שינוי גודל טקסט') }}</label>
							<button type="button" :title="$t('הגדלת טקסט')" @click="increaseFontSize">+</button>
							<button type="button" :title="$t('הקטנת טקסט')">-</button>
					</div>
					<button type="button" @click="toggleAccessibilityModal">{{ $t('סגירה') }} &times;</button>
					<button type="button">{{ $t('איפוס') }} &ominus;</button>
			</div>
	</transition>
</template>

<script>
import {mapGetters} from "vuex";

export default {
		name: "AccessibilityModal",
		data(){
				return {
						actions: {
								mono_color: false,
								invert_color: false,
								brighten_colors: false,
								dark_colors: false,
								block_blinks: false,
								links_underline: false,
								disable_font_styles: false,
						},
						fontSize: 16,
						styleElement: null,
						blanketElement: null
				}
		},
		computed: {
				...mapGetters(['accessibilityModal'])
		},
		watch:{
				accessibilityModal(newVal){
						if(newVal){
								this.showBlanket()
						}else{
								this.hideBlanket()
						}
				}
		},
		methods:{
				applyActions(){
						this.styleElement.textContent = ''
						if(this.actions.mono_color){
								this.styleElement.textContent += `
									body{
										-webkit-filter: grayscale(100%);
										-moz-filter: grayscale(100%);
										-ms-filter: gray;
										filter: grayscale(100%);
									}
								`
						}
						if(this.actions.invert_color){
								this.styleElement.textContent += `
									body {
										-webkit-filter: invert(100%);
										-moz-filter: invert(100%);
										-ms-filter: invert;
										filter: invert(100%);
									}
								`
						}
						if(this.actions.brighten_colors){
								this.styleElement.textContent += `
								div, body, header, footer, section, artical, p, a, ul, li, h1, h2, h3, h4, h5, h6, button, input, label, span, textarea {
										background-color: #fff !important;
										background-image: none !important;
										color: blue !important;
								}
								svg {
									fill: blue !important;
									stroke: blue !important;
								}
								`
						}
						if(this.actions.dark_colors){
								this.styleElement.textContent += `
								div, body, header, footer, section, artical, p, a, ul, li, h1, h2, h3, h4, h5, h6, button, input, label, span, textarea {
										background-color: #000 !important;
										background-image: none !important;
										color: #ff0 !important;
								}
								svg {
									fill: blue !important;
									stroke: blue !important;
								}
								`
						}

						if(this.actions.block_blinks) {
								this.styleElement.textContent += `
							*:not(.accessibility-modal) {
								-o-transition-property: none !important;
								-moz-transition-property: none !important;
								-ms-transition-property: none !important;
								-webkit-transition-property: none !important;
								transition-property: none !important;
								-o-transform: none !important;
								-moz-transform: none !important;
								-ms-transform: none !important;
								-webkit-transform: none !important;
								transform: none !important;
								-webkit-animation: none !important;
								-moz-animation: none !important;
								-o-animation: none !important;
								-ms-animation: none !important;
								animation: none !important;
							}
						`
						}
						if(this.actions.links_underline) {
								this.styleElement.textContent += `
									a {
										text-decoration: underline !important;
									}
								`
						}
						if(this.actions.disable_font_styles) {
								this.styleElement.textContent += `
									body, body div, body p, body span, body label, body a, body h1, body h2, body h3, body h4, body h5, body h6, body strong, body small, body address, body li, body input, body button {
										font-family: arial, sans-serif !important;
										font-style: normal !important;
									}
								`
						}
				},
				hideBlanket(){
						this.blanketElement.classList.remove('am-blanket')
				},
				showBlanket(){
						this.blanketElement.classList.add('am-blanket')
				},
				toggleAccessibilityModal(){
						this.$store.commit('toggleAccessibilityModal')
				},
				increaseFontSize(){
						//const elements = document.querySelectorAll('body,div,p,span,label,a,h1,h2,h3,h4,h5,h6,strong,small,address,li,input')
						/*elements.forEach((element) => {
								//console.log(element)
								//element.dataset.aof = element.style.fontSize
						})*/
						/*$("body,div,p,span,label,a,h1,h2,h3,h4,h5,h6,strong,small,address,li,input").each(function(index, el) {
								if ($(this).closest('#upsite-am').length == 0) {
										var fontSize = parseInt($(this).css("font-size"));
										fontSize = fontSize + 1 + "px";
										$(this).css({'font-size':fontSize});
								};
						});*/
				}
		},
		mounted() {
				this.styleElement = document.createElement('style')
				this.blanketElement = document.createElement('div')
				document.head.appendChild(this.styleElement)
				document.body.appendChild(this.blanketElement)
		}
}
</script>

<style scoped lang="scss">
	.accessibility-modal {
			position: absolute;
			z-index: 999;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: #fff;
			color: #000;
			width: 400px;
			max-width: 100%;
			padding: 15px;
			font-size: 1.5rem;

			button {
				border: solid 1px;
				font-size: 1.5rem;
				margin: 2px 5px;

				&:focus {
					outline: solid 2px;
				}
			}

			&.v-enter-active,
			&.v-leave-active {
					transition: transform .3s ease;
			}

			&.v-enter-from,
			&.v-leave-to {
					transform: translateX(-50%) translateY(300px);
			}

			.am-controls {
					margin-bottom: 15px;
			}
	}
</style>

<style>
.am-blanket {
		position: fixed;
		z-index: 190;
		background-color: rgba(0, 0, 0, 0.40) !important;
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
}
</style>