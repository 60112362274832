module.exports = {
    runtimeCompiler: true,
    filenameHashing: true,
    publicPath: '/wp-content/plugins/mooz360-fp/floorplan-app',
    outputDir: '../floorplan-app',
    chainWebpack: config => {
        config.module
            .rule('vue')
            .use('vue-loader')
            .tap(options => ({
                ...options,
                compilerOptions: {
                    isCustomElement: (tag) => ['lord-icon','lottie-player'].includes(tag),
                },
            }))
    }
}
